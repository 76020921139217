import { createContext, useContext } from 'react';

const ContainerContext = createContext('mobile');

export const ContainerContextProvider = ContainerContext.Provider;

export const useContainerContext = () => {
  const data = useContext(ContainerContext);

  if (data == null) {
    throw Error(
      'Please wrap this component in Container Context Provider and pass non null value',
    );
  }

  const { loading, enableLoading, disableLoading } = data;

  return { loading, enableLoading, disableLoading };
};

export default ContainerContext;
