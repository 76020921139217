import Typography from 'antd/lib/typography';
import SmartSelectorSwitch from '.';
import { useSettingsContext } from '../../context/SettingsContext';

const { Text, Link } = Typography;

function ToggleMarketSelector() {
  const {
    settings: { hasMultipleDomain },
  } = useSettingsContext();

  // Error message for the shop having multiple domains
  const errorMessage = <Text strong>Important Note: </Text>;

  const errorDescription = (
    <Text>
      Auto detect feature will not work on stores having multiple domains. If
      you don&apos;t have multiple domains and want to enable it, please contact
      us at <Link href='mailto:support@mlveda.com'>support@mlveda.com</Link>.
    </Text>
  );

  return (
    <SmartSelectorSwitch
      name='marketAutoDetect'
      title='Auto detect market'
      description='Enabling this option lets your customers to see the prices in their local currency by default. If you
      enable this then your customers do not need to choose the currency manually from the
      market
      selector.'
      errorMessage={errorMessage}
      errorDescription={errorDescription}
      disabled={hasMultipleDomain}
    />
  );
}

export default ToggleMarketSelector;
