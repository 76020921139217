import Space from 'antd/lib/space';
import Typography from 'antd/lib/typography';

const { Text } = Typography;

function FormLabel({ title, subTitle }) {
  return (
    <Space direction='vertical' size={0}>
      <Text strong>{title}</Text>
      {subTitle && <Text>{subTitle}</Text>}
    </Space>
  );
}

export default FormLabel;
