import { createContext, useContext } from 'react';

const ShopContext = createContext('mobile');

export const ShopContextProvider = ShopContext.Provider;

export const useShopContext = () => {
  const shops = useContext(ShopContext);

  if (shops == null) {
    throw Error(
      'Please wrap this component in ShopContextProvider and pass non null value',
    );
  }

  return shops;
};

export default ShopContext;
