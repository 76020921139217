const languages = [
  {
    name: 'English',
    id: 'en',
    iso_code: 'EN',
    custom: {
      name: 'English',
      iso_code: 'EN',
    },
  },
  {
    name: 'Chinese(Basic)',
    id: 'zh-cn',
    iso_code: 'ZH-CH',
    custom: {
      name: '中国人',
      iso_code: 'W-H',
    },
  },
  {
    name: 'Spanish',
    id: 'es',
    iso_code: 'ES',
    custom: {
      name: 'Español',
      iso_code: 'ES',
    },
  },
  {
    name: 'French',
    id: 'fr',
    iso_code: 'FR',
    custom: {
      name: 'français',
      iso_code: 'FR',
    },
  },
  {
    name: 'Arabic',
    id: 'ar',
    iso_code: 'AR',
    custom: {
      name: 'عربي',
      iso_code: 'مع',
    },
  },

  {
    name: 'Hindi',
    id: 'hi',
    iso_code: 'HI',
    custom: {
      name: 'हिन्दी',
      iso_code: 'हिन्दी',
    },
  },
];

export { languages };
