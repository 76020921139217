import Progress from 'antd/lib/progress';

import classes from './index.module.less';

function ProgressBar({ stepReached = 1 }) {
  const percent = ((stepReached - 1) / 5) * 100;
  return (
    <div className={classes.progressBar}>
      <Progress percent={percent} showInfo={false} trailColor='#e5e5e5' />
    </div>
  );
}

export default ProgressBar;
