import Form from 'antd/lib/form';
import { useAppContext } from '../../context/AppContext';
import { usePrefixContext } from '../../context/PrefixContext';
import DesignSection from './DesignSection';
// import InlineHeaderDesign from './InlineHeaderDesign';

function MarketSelectorDesign() {
  const prefix = usePrefixContext();
  const { designs } = useAppContext();
  return (
    <Form.Item
      dependencies={[
        `${prefix}.position`,
        `${prefix}.switcher.mappedToDesign`,
        `${prefix}.switcher.flagStyle`,
        `${prefix}.switcher.countryStyle`,
        `${prefix}.switcher.currencyStyle`,
        `${prefix}.switcher.showFlag`,
      ]}
    >
      {(form) => {
        // Get selected switcher style
        const switcherStyle = form.getFieldValue(`${prefix}.position`);

        // store the designs data which matches the conditions based on switcher style
        let designData = [];

        // fetch switcher designs for fixed position
        if (switcherStyle === 'fixed') {
          designData = [...designs].filter(
            (item) =>
              item[prefix]?.position === 'fixed' &&
              item[prefix]?.switcherType === 'switcher',
          );
        }
        // fetch switcher designs for inline with header position
        if (switcherStyle === 'inline_with_header') {
          designData = [...designs].filter(
            (item) =>
              item[prefix]?.position === 'inline_with_header' &&
              item[prefix]?.switcherType === 'switcher',
          );
        }

        if (switcherStyle !== 'none') {
          return (
            <DesignSection designs={designData} form={form} prefix={prefix} />
          );
        }

        return null;
      }}
    </Form.Item>
  );
}

export default MarketSelectorDesign;
