import Form from 'antd/lib/form';
import Select from 'antd/lib/select';
import Space from 'antd/lib/space';
import Typography from 'antd/lib/typography';
import InlineHeader from './InlineHeader';
import FixedHeader from './FixedHeader';
import DontShow from './DontShow';
import { usePrefixContext } from '../../context/PrefixContext';
import { useAppContext } from '../../context/AppContext';

const { Text, Link } = Typography;
const { Option } = Select;

function MarketSelector({ themeInfo }) {
  const prefix = usePrefixContext();
  const { designs } = useAppContext();

  return (
    <Form.Item
      dependencies={[
        `${prefix}.position`,
        `${prefix}.switcher.mappedToDesign`,
        `${prefix}.switcher.flagStyle`,
        `${prefix}.position`,
      ]}
    >
      {(form) => {
        // Get the selected position and display respective component
        const position = form.getFieldValue(`${prefix}.position`);
        let markup;
        if (position === 'inline_with_header') {
          markup = <InlineHeader />;
        } else if (position === 'fixed') {
          markup = <FixedHeader />;
        } else if (position === 'none') {
          markup = <DontShow />;
        }

        // if (themeInfo) {
        //   form.validateFields();
        // }

        return (
          <Space direction='vertical' size={24} style={{ width: '100%' }}>
            <Form.Item
              name={`${prefix}.position`}
              label={<Text strong>Switcher Location</Text>}
              // hasFeedback
              rules={[
                {
                  warningOnly: true,
                  message: (
                    <>
                      <div>
                        Theme {themeInfo?.themeName} is not compatible as of now
                        for inline with header.
                      </div>
                      <div>
                        If you want to have inline with header for your theme
                        please contact us at{' '}
                        <Link href='mailto:support@mlveda.com'>
                          support@mlveda.com
                        </Link>
                        .
                      </div>
                    </>
                  ),
                  validator: (rule, value) => {
                    // Check for theme is compatible or not
                    // if not compatible reject the promise
                    // which lead to the error/warning message
                    if (
                      value === 'inline_with_header' &&
                      !themeInfo?.compatible
                    ) {
                      return Promise.reject();
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              normalize={(val) => {
                if (val === 'none') {
                  return val;
                }
                // Update predefined design's selected keys
                // currently on every change updating to the 1st design relative to the position and desktop/mobile.
                const data = [...designs].filter((design) => {
                  if (
                    design[prefix]?.position === val &&
                    design[prefix]?.switcherType === 'switcher'
                  ) {
                    return true;
                  }
                  return false;
                })[0][prefix];

                // values object which holds every key to be updated in the form state.
                const values = {};
                values[`${prefix}.switcher.countryStyle`] =
                  data?.switcher?.countryStyle;
                values[`${prefix}.switcher.currencyStyle`] =
                  data?.switcher?.currencyStyle;
                values[`${prefix}.switcher.designId`] = data?.designId;
                values[`${prefix}.switcher.flagStyle`] =
                  data?.switcher?.flagStyle;
                values[`${prefix}.switcher.showFlag`] =
                  data?.switcher?.showFlag;

                // update all the values in the form
                form.setFieldsValue(values);

                return val;
              }}
            >
              <Select>
                <Option value='inline_with_header'>
                  Inline with the header
                </Option>
                <Option value='fixed'>Fixed position</Option>
                <Option value='none'>Don&apos;t show at all</Option>
              </Select>
            </Form.Item>

            {markup}
          </Space>
        );
      }}
    </Form.Item>
  );
}

export default MarketSelector;
