import { Col, Row } from 'antd/lib/grid';
import Form from 'antd/lib/form';
import Radio from 'antd/lib/radio';
import Space from 'antd/lib/space';
import Select from 'antd/lib/select';
import Checkbox from 'antd/lib/checkbox';
import Typography from 'antd/lib/typography';
import Popover from 'antd/lib/popover';
import Avatar from 'antd/lib/avatar';

import RadioButton from '../RadioButton';
import { useSettingsContext } from '../../context/SettingsContext';

import classes from './languageSwitcher.module.less';
import { usePrefixContext } from '../../context/PrefixContext';
import CustomChromePicker from '../CustomChromePicker';
import { useShopContext } from '../../context/ShopContext';

const { Text } = Typography;

function LanguageSwitcher() {
  const { settings } = useSettingsContext();
  const shop = useShopContext();
  const prefix = usePrefixContext();

  const colors = [
    {
      key: `${prefix}.buttonTextColor`,
      label: 'Text color',
    },
    {
      key: `${prefix}.buttonBackgroundColor`,
      label: 'Background color',
    },
  ];

  return (
    <div>
      <Text strong className={classes.title}>
        Do you want to enable language switcher on your store?
      </Text>
      <Text style={{ marginBottom: '1em', display: 'block' }}>
        Adding language switcher lets your customers to spend more time on your
        store and may help you in getting more sales.
      </Text>

      <Space direction='vertical' size={48} style={{ width: '100%' }}>
        <Form.Item
          dependencies={[
            `${prefix}.showLanguageSwitcher`,
            `${prefix}.buttonText`,
            `${prefix}.buttonTextColor`,
            `${prefix}.buttonBackgroundColor`,
          ]}
        >
          {({ getFieldValue }) => (
            <Space
              direction='vertical'
              size={24}
              style={{ display: 'flex', width: '100%' }}
            >
              <Form.Item name={`${prefix}.showLanguageSwitcher`}>
                <Radio.Group className={classes.radioGroup}>
                  <Space wrap style={{ display: 'flex' }}>
                    <RadioButton
                      type='ghost'
                      name='yes'
                      text='Yes'
                      block
                      value
                    />
                    <RadioButton
                      type='ghost'
                      name='no'
                      text='No'
                      block
                      value={false}
                    />
                  </Space>
                </Radio.Group>
              </Form.Item>
              {/* onboarding is completed then show advanced options  */}
              {settings.isOnboardingCompleted &&
                getFieldValue(`${prefix}.showLanguageSwitcher`) && (
                  <Space
                    direction='vertical'
                    style={{ display: 'flex' }}
                    size={12}
                  >
                    <Space direction='vertical' size={12}>
                      <Form.Item
                        name={`${prefix}.switcher.languageSwitcherStyle`}
                        label={<Text strong>Language in the switcher</Text>}
                      >
                        <Select>
                          <Select.Option value='name'>Name</Select.Option>
                          <Select.Option value='iso_code'>
                            2 digit code
                          </Select.Option>
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name={`${prefix}.switcherDropDown.languageSwitcherStyle`}
                        label={
                          <Text strong>Language in the switcher dropdown</Text>
                        }
                      >
                        <Select>
                          <Select.Option value='name'>Name</Select.Option>
                          <Select.Option value='iso_code'>
                            2 digit code
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Space>
                    <Form.Item
                      name={`${prefix}.useOwnLanguage`}
                      valuePropName='checked'
                    >
                      <Checkbox>Use language in its own language.</Checkbox>
                    </Form.Item>
                    {/* <Form.Item
                      name='languageHeadingText'
                      label={<Text strong>Heading text</Text>}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name='languageButtonText'
                      label={<Text strong>Button text</Text>}
                    >
                      <Input />
                    </Form.Item> */}
                    <Row style={{ cursor: 'pointer', padding: '5px 3px' }}>
                      {colors.map((color) => (
                        <Col key={color.key} span={12}>
                          <Popover
                            overlayClassName={classes.popoverOverlay}
                            trigger='click'
                            content={<CustomChromePicker name={color.key} />}
                          >
                            <Row gutter={10} align='middle' wrap={false}>
                              <Col>
                                <Form.Item dependencies={[color.key]} noStyle>
                                  {(form) => (
                                    <Avatar
                                      shape='square'
                                      style={{
                                        backgroundColor: form.getFieldValue(
                                          color.key,
                                        ),
                                        boxShadow:
                                          'rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px',
                                      }}
                                    />
                                  )}
                                </Form.Item>
                              </Col>
                              <Col>
                                <Text strong>{color.label}</Text>
                              </Col>
                            </Row>
                          </Popover>
                        </Col>
                      ))}
                    </Row>
                  </Space>
                )}
            </Space>
          )}
        </Form.Item>
        <div>
          <Text strong>Note: </Text>
          <Text>
            To support more languages, please add languages first from{' '}
            <a
              href={`https://${shop?.myshopify_domain}/admin/settings/languages`}
              target='_blank'
              rel='noreferrer'
            >
              shopify settings
            </a>
          </Text>
        </div>
      </Space>
    </div>
  );
}

export default LanguageSwitcher;
