import Slider from 'antd/lib/slider';

export default function SliderWithFormatterAndParser({
  parser,
  formatter,
  value = '0px',
  onChange,
  ...props
}) {
  const sliderVal = parser ? parser(value) : value;
  const sliderOnChange = formatter
    ? (val) => {
        onChange(formatter(val));
      }
    : onChange;

  return <Slider {...props} value={sliderVal} onChange={sliderOnChange} />;
}
