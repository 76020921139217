import { memo } from 'react';
import Badge from 'antd/lib/badge';
import Image from 'antd/lib/image';
import Radio from 'antd/lib/radio';
import Space from 'antd/lib/space';
import Tag from 'antd/lib/tag';
import Typography from 'antd/lib/typography';
import CheckCircleFilled from '@ant-design/icons/lib/icons/CheckCircleFilled';

import { countries } from '../../utils/Mappings/countryList';
import classes from './switcherDesign.module.less';

function SwitcherDesign({
  id,
  value,
  description,
  selected,
  code,
  currencyStyle,
  recommended,
  handleClick,
  imageSrc,
}) {
  const country = countries.find((item) => item.id === 'usa');

  return (
    <Space direction='vertical' size={0} className={classes.root}>
      {recommended && (
        <div className={classes.recommended}>
          <Tag>RECOMMENDED</Tag>
        </div>
      )}
      <Badge
        className={classes.badge}
        count={
          selected ? (
            <CheckCircleFilled className={classes.checkedIcon} />
          ) : null
        }
      >
        <Radio.Button
          key={id}
          value={value}
          checked={selected}
          onClick={() => handleClick(value)}
          className={`${selected ? classes.selected : classes.btn} ${
            classes.radioButton
          }`}
        >
          <Space direction='vertical' size={0}>
            <Space className={classes.content} size={3}>
              {/* for design have image or not */}
              {imageSrc && (
                <Image preview={false} src={imageSrc} width={24} height={24} />
              )}
              {/* for design have country name/code or not */}
              {code && (
                <Typography.Text className={classes.text}>
                  {country[code]}
                </Typography.Text>
              )}
              {/* for design have currency name/code or not */}
              {currencyStyle !== 'none' && (
                <Typography.Text>{`${code !== 'none' ? '(' : ''}${
                  country.currency[currencyStyle]
                }${code !== 'none' ? ')' : ''}`}</Typography.Text>
              )}
            </Space>
            <Typography.Text className={classes.description}>
              {description}
            </Typography.Text>
          </Space>
        </Radio.Button>
      </Badge>
    </Space>
  );
}

export default memo(SwitcherDesign);
