/* eslint-disable no-plusplus */
import { useEffect, useCallback } from 'react';
import flat from 'flat';
import $ from 'jquery';
// eslint-disable-next-line no-unused-vars
import select2 from 'select2';

import 'select2/dist/css/select2.min.css';
import './select2.css';
import { countries } from '../../utils/Mappings/countryList';
import classes from './countrySelector.module.less';

function Select2({ formData, device, showSearchBar }) {
  const settingsData = flat.unflatten(formData);

  // dropdown options
  function mlvedaLoadCountriesList(deviceType) {
    const html = countries?.map((country) => (
      <option key={country?.id} value={country?.id}>
        {settingsData[deviceType]?.switcherDropDown?.countryStyle !== 'none'
          ? country[settingsData[deviceType]?.switcherDropDown?.countryStyle]
          : ''}{' '}
        {settingsData[deviceType]?.switcherDropDown?.currencyStyle !== 'none'
          ? `${
              settingsData[device].switcherDropDown.countryStyle !== 'none'
                ? '('
                : ''
            } ${
              country.currency[
                settingsData[deviceType]?.switcherDropDown?.currencyStyle
              ]
            } ${
              settingsData[device].switcherDropDown.countryStyle !== 'none'
                ? ')'
                : ''
            }`
          : ''}
      </option>
    ));

    return html;
  }

  // Dropdown options Template
  //  formats the options
  const templateResult = useCallback(
    (state) => {
      if (!state.id) return state.text;
      const country = countries.find((data) => data.id === state.id);

      // update the html content for the options
      // if showFlag then flag style based on class image-rectangle / image-circle
      // for the flags class will be image-{{countryCode}} which will be available in country.js Mappings file
      const $state = $(
        `<span class="mlveda-result-main">${
          settingsData[device].switcherDropDown.showFlag
            ? `<span class="mlveda-image-span image-${
                settingsData[device].switcherDropDown.flagStyle
              } ${
                settingsData[device].switcherDropDown.countryStyle !== 'none' ||
                settingsData[device].switcherDropDown.currencyStyle !== 'none'
                  ? ' mlveda-mr-8'
                  : ''
              }"><span class="mlveda-flag ${
                settingsData[device].switcherDropDown.flagStyle
              } ${settingsData[device].switcherDropDown.flagStyle}-${
                country.flag_code
              } flag-img"></span>
            </span>`
            : ''
        }
        <span class="mlveda-result-span mlveda-mr-8">${
          settingsData[device].switcherDropDown.countryStyle !== 'none'
            ? country[settingsData[device].switcherDropDown.countryStyle]
            : ''
        }${' '}${
          settingsData[device].switcherDropDown.currencyStyle !== 'none'
            ? `${
                settingsData[device].switcherDropDown.countryStyle !== 'none'
                  ? '('
                  : ''
              }${
                country.currency[
                  settingsData[device].switcherDropDown.currencyStyle
                ]
              }${
                settingsData[device].switcherDropDown.countryStyle !== 'none'
                  ? ')'
                  : ''
              }`
            : ''
        }</span>
     </span>`,
      );

      return $state;
    },
    [device, settingsData],
  );

  // Dropdown Selected value's Template
  const templateSelection = useCallback(
    (state) => {
      const country = countries.find((data) => data.id === state.id);

      // update the html content for the selected value
      // if showFlag then flag style based on class image-rectangle / image-circle
      // for the flags class will be image-{{countryCode}} which will be available in country.js Mappings file
      const $state = $(
        `<span class='mlveda-result-main result-selected'>${
          settingsData[device].switcher.showFlag
            ? `<span class="mlveda-image-span image-${
                settingsData[device].switcher.flagStyle
              } ${
                settingsData[device].switcher.countryStyle !== 'none' ||
                settingsData[device].switcher.currencyStyle !== 'none'
                  ? ' mlveda-mr-8'
                  : ''
              }"><span class="mlveda-flag ${
                settingsData[device].switcher.flagStyle
              } ${settingsData[device].switcher.flagStyle}-${
                country.flag_code
              } flag-img"></span>
            </span>`
            : ''
        } 
        ${`<span class="mlveda-result-span mlveda-mr-8">${
          settingsData[device].switcher.countryStyle !== 'none'
            ? country[settingsData[device].switcher.countryStyle]
            : ' '
        }${' '}${
          settingsData[device].switcher.currencyStyle !== 'none'
            ? `${
                settingsData[device].switcher.countryStyle !== 'none' ? '(' : ''
              }${
                country.currency[settingsData[device].switcher.currencyStyle]
              }${
                settingsData[device].switcher.countryStyle !== 'none' ? ')' : ''
              }`
            : ''
        }</span>`}
      </span>`,
      );

      return $state;
    },
    [device, settingsData],
  );

  // useEffect(
  //   () => () => {
  //     if ($('#country-selector')?.hasClass('select2-hidden-accessible')) {
  //       $('#country-selector').select2('destroy');
  //     }
  //   },
  //   [device],
  // );

  useEffect(() => {
    // Select 2 implementation with JQuery
    $('#country-selector').select2({
      templateResult,
      templateSelection,
      dropdownParent: $('#country-selector-wrapper'),
    });

    // On dropdown open add search field's placeholder
    $('#country-selector').on('select2:open', (e) => {
      $('.select2-search__field').attr('placeholder', 'Search...');
    });
  }, [templateResult, templateSelection]);

  useEffect(() => {
    /**
     * *param {showSearchBar === true} => remove no-searchbar class
     * *param {showSearchBar === false} => add no-searchbar class to hide searchbar from the select2 dropdown
     */
    if (showSearchBar) {
      $('#country-selector').on('select2:open', (e) => {
        $('.select2-search').removeClass('no-searchbar');
      });
    } else {
      $('#country-selector').on('select2:open', (e) => {
        $('.select2-search').addClass('no-searchbar');
      });
    }
  }, [showSearchBar]);

  return (
    <div
      key={device}
      id='country-selector-wrapper'
      className={classes.modalContent}
    >
      <select
        data-select2-id='country'
        defaultValue='US'
        className='custom-select'
        name='country-selector'
        id='country-selector'
      >
        {mlvedaLoadCountriesList(device)}
      </select>
    </div>
  );
}

export default Select2;
