import axios from 'axios';
import Space from 'antd/lib/space';
import { Col, Row } from 'antd/lib/grid';
import { unflatten } from 'flat';
import Button from 'antd/lib/button';
import message from 'antd/lib/message';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useSettingsContext } from '../../../context/SettingsContext';
import StepPageLayout from '../StepPageLayout';
import AutoGeoLocation from '../../../components/AutoGeoLocation';
import SetupCompleted from '../SetupCompleted';

function Step1() {
  const navigate = useNavigate();
  const { handleSettingsData, settings } = useSettingsContext();
  const [nextBtnLoading, setNextBtnLoading] = useState(false);
  const [backBtnLoading, setBackBtnLoading] = useState(false);
  const [isSetupCompleted, setIsSetupCompleted] = useState(false);

  const handleFinish = (form) => {
    setNextBtnLoading(true);
    const data = {
      ...unflatten(form.getFieldsValue(true)),
      stepReached: 6,
      // isOnboardingCompleted: true,
    };
    axios
      .put('/settings', data)
      .then((res) => {
        handleSettingsData(res.data);
        setNextBtnLoading(false);
        // navigate('/settings');
        setIsSetupCompleted(true);
      })
      .catch(() => {
        message.error('Something went wrong');
        setNextBtnLoading(false);
      });
  };

  const handleBack = () => {
    setBackBtnLoading(true);
    if (settings?.desktop?.position === 'none') {
      const data = { stepReached: 1 };
      axios
        .put('/settings', data)
        .then((res) => {
          handleSettingsData(res.data);
          setBackBtnLoading(false);
          navigate('/step-1');
        })
        .catch(() => {
          message.error('Something went wrong');
          setBackBtnLoading(false);
        });
    } else {
      const data = { stepReached: 4 };
      axios
        .put('/settings', data)
        .then((res) => {
          handleSettingsData(res.data);
          setBackBtnLoading(false);
          navigate('/step-4');
        })
        .catch(() => {
          message.error('Something went wrong');
          setBackBtnLoading(false);
        });
    }
  };

  return [
    <StepPageLayout
      key='step-5'
      handleFinish={handleFinish}
      spinning={nextBtnLoading || backBtnLoading}
      title='Step 5: Auto detection'
      subTitle='Enable the below settings to make your store look good for all your international customers'
    >
      <Row className='stepContainer'>
        <Col xs={24} md={24} className='leftSection'>
          <AutoGeoLocation />
        </Col>

        <Col xs={24} md={24}>
          <div className='save-btn-wrapper'>
            <Space align='center' size={24}>
              <Button
                style={{ width: 85 }}
                loading={backBtnLoading}
                onClick={handleBack}
              >
                Back
              </Button>
              <Button
                type='primary'
                htmlType='submit'
                style={{ width: 170 }}
                loading={nextBtnLoading}
              >
                Finish
                <i className='las la-arrow-right' />
              </Button>
            </Space>
          </div>
        </Col>
      </Row>
    </StepPageLayout>,
    isSetupCompleted && <SetupCompleted />,
  ];
}

export default Step1;
