import { createContext, useContext } from 'react';

const AppContext = createContext('mobile');

export const AppContextProvider = AppContext.Provider;

export const useAppContext = () => {
  const data = useContext(AppContext);

  if (data === undefined) {
    throw Error(
      'Please wrap this component in App Context Provider and pass non null value',
    );
  }

  const { designs } = data;

  return { designs };
};

export default AppContext;
