import { Suspense, useState } from 'react';
import Layout from 'antd/lib/layout';
import { Content } from 'antd/lib/layout/layout';
// import FooterSection from '../FooterSection';
import { Outlet } from 'react-router-dom';

import { Spin } from 'antd';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import classes from './index.module.less';
import LoadingPage from '../../../components/LoadingPage';
import { ContainerContextProvider } from '../../../context/ContainerContext';

const ContainerLayout = function ContainerLayout({ topBar }) {
  const [loading, setLoading] = useState(false);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  return (
    <Layout className={classes.layout}>
      <Spin
        className={classes.spinnerContainer}
        spinning={loading}
        indicator={<LoadingOutlined />}
      >
        <ContainerContextProvider
          value={{ loading, enableLoading, disableLoading }}
        >
          {topBar}
          <Content className={classes.mainContent}>
            <Suspense fallback={<LoadingPage />}>
              <Outlet />
            </Suspense>
          </Content>
        </ContainerContextProvider>
      </Spin>
    </Layout>
  );
};

export default ContainerLayout;
