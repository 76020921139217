import Modal from 'antd/lib/modal/Modal';
import Result from 'antd/lib/result';
import Button from 'antd/lib/button';
// import { Image } from 'antd';
// import checkedIcon from '../../../assets/images/icons8-ok.gif';
// import excalimationIcon from '../../../assets/images/exclamation-mark-64.png';

import classes from './appInstallMessage.module.less';

function ValidationMessage({
  primaryBtnText,
  secondaryBtnText,
  title,
  subTitle,
  status,
  handlePrimaryBtn,
  handleSecondaryBtn,
  loading,
  closable = false,
  onCancel,
  isValidated = true,
}) {
  return (
    <Modal
      visible
      centered
      className={classes.validationModal}
      closable={closable}
      footer={null}
      title={null}
      maskTransitionName={null}
      onCancel={onCancel}
      maskClosable={false}
      maskStyle={{ backgroundColor: '#36363678' }}
    >
      <Result
        status={status}
        title={title}
        subTitle={subTitle}
        extra={[
          <Button
            type='primary'
            key='primary'
            loading={loading}
            onClick={handlePrimaryBtn}
            style={{ minWidth: 100 }}
          >
            {primaryBtnText}
          </Button>,
          secondaryBtnText && (
            <Button
              type='ghost'
              disabled={loading}
              key='secondary'
              onClick={handleSecondaryBtn}
            >
              {secondaryBtnText}
            </Button>
          ),
        ]}
      />
    </Modal>
  );
}

export default ValidationMessage;
