import Typography from 'antd/lib/typography';
import { useShopContext } from '../../context/ShopContext';
import classes from './marketSelector.module.less';

const { Text, Link } = Typography;

function InlineHeader() {
  const shop = useShopContext();
  return (
    <div>
      <ul>
        <li className={classes.listItem}>
          <Text>
            This feature is supported default by all{' '}
            {/* link to the list of compatible themes */}
            <Link
              target='_blank'
              href='https://docs.google.com/document/d/1ACST03DQMqrYbhPLGUTY-rcePhIpfqU0VbUaHFjJ6nY'
            >
              popular themes
            </Link>{' '}
            in shopify.
          </Text>
        </li>
        <li className={classes.listItem}>
          <Text>
            If you have any other theme and wish to blend the market selector in
            header for desktop and in menu for mobile, then
          </Text>
          <ol>
            <li className={classes.listItem}>
              <Text>
                Please add{' '}
                <Link
                  className={classes.subText}
                  href='mailto:shopify@mlveda.com'
                >
                  shopify@mlveda.com
                </Link>{' '}
                as staff for your store with permissions to Themes, Apps,
                Settings, Products, Domains and Analytics from{' '}
                <Link
                  target='_blank'
                  href={`https://${shop?.myshopify_domain}/admin/settings/account/new `}
                >
                  here
                </Link>
                . The products, domains and analytics permission is needed to
                have the access of markets.
              </Text>
            </li>
            <li className={classes.listItem}>
              <Text>
                Send us an email to{' '}
                <Link href='mailto:support@mlveda.com'>support@mlveda.com</Link>{' '}
                with your requirement(s)
              </Text>
            </li>
          </ol>
        </li>
      </ul>
    </div>
  );
}

export default InlineHeader;

// import Form from 'antd/lib/form';
// import Select from 'antd/lib/select';
// import Space from 'antd/lib/space';
// import Radio from 'antd/lib/radio';
// import Typography from 'antd/lib/typography';
// import { Row, Col } from 'antd/lib/grid';
// import SelectorDesign from '../../../components/SelectorDesign';
// import FormLabel from '../../../components/FormLabel';

// const { Text } = Typography;
// const { Option } = Select;

// function InlineHeader() {
//   return (
//     <Form.Item
//       dependencies={[
//         'switcherStyle',
//         'desktopInlineSelectorDesign',
//         'desktopInlineDesignType',
//       ]}
//     >
//       {(form) => {
//         const selected = form.getFieldValue('desktopInlineSelectorDesign');

//         const handleDesignSelect = (newValue) => {
//           form.setFieldsValue({
//             desktopInlineSelectorDesign: newValue,
//           });
//         };
//         return (
//           <Form.Item name='desktopInlineDesignType'>
//             <Radio.Group>
//               <Space direction='vertical' size={24}>
//                 <Space direction='vertical'>
//                   <Radio value='fromDesign'>
//                     <Space direction='vertical' size={0}>
//                       <Text strong>Choose from existing designs</Text>
//                       <Text>Help text will come here</Text>
//                     </Space>
//                   </Radio>
//                   {form.getFieldValue('desktopInlineDesignType') ===
//                     'fromDesign' && (
//                     <div className='pl-24'>
//                       <Form.Item name='desktopInlineSelectorDesign'>
//                         {/* <Radio.Group> */}
//                         <Row gutter={[12, 12]} wrap justify='space-between'>
//                           <Col xs={24} md={12}>
//                             <SelectorDesign
//                               selected={selected === 'flag'}
//                               name='flag'
//                               value='flag'
//                               description='flag (highly preferred)'
//                               handleClick={handleDesignSelect}
//                             />
//                           </Col>
//                           <Col xs={24} md={12}>
//                             <SelectorDesign
//                               selected={selected === 'flagAnd2Code'}
//                               name='flagAnd2Code'
//                               value='flagAnd2Code'
//                               code='IN'
//                               description='flag + 2 digit country code'
//                               handleClick={handleDesignSelect}
//                             />
//                           </Col>
//                           <Col xs={24} md={12}>
//                             <SelectorDesign
//                               selected={selected === 'flagAnd3Code'}
//                               name='flagAnd3Code'
//                               value='flagAnd3Code'
//                               code='INR'
//                               description='flag + 3 digit country code'
//                               handleClick={handleDesignSelect}
//                             />
//                           </Col>
//                           <Col xs={24} md={12}>
//                             <SelectorDesign
//                               selected={selected === 'flagAndSymbol'}
//                               name='flagAndSymbol'
//                               value='flagAndSymbol'
//                               symbol='₹'
//                               description='flag + currency symbol'
//                               handleClick={handleDesignSelect}
//                             />
//                           </Col>
//                         </Row>
//                         {/* </Radio.Group> */}
//                       </Form.Item>
//                     </div>
//                   )}
//                 </Space>
//                 <Space direction='vertical'>
//                   <Radio value='customDesign'>
//                     <Space direction='vertical' size={0}>
//                       <Text strong>I will create my own design</Text>
//                       <Text>Help text will come here</Text>
//                     </Space>
//                   </Radio>
//                   {form.getFieldValue('desktopInlineDesignType') ===
//                     'customDesign' && (
//                     <Space
//                       className='pl-24'
//                       direction='vertical'
//                       size={12}
//                       // className={classes.subSection}
//                     >
//                       <Form.Item
//                         name='desktopInlineMarketSelectorFlag'
//                         label={
//                           <FormLabel
//                             title='Flag'
//                             subTitle='Help text will come here'
//                           />
//                         }
//                       >
//                         <Select>
//                           <Option value='rounded'>Rounded</Option>
//                           <Option value='rectangular'>Rectangular</Option>
//                           <Option value='no'>No</Option>
//                         </Select>
//                       </Form.Item>
//                       <Form.Item
//                         name='desktopInlineMarketSelectorCountry'
//                         label={
//                           <FormLabel
//                             title='Country'
//                             subTitle='Help text will come here'
//                           />
//                         }
//                       >
//                         <Select>
//                           <Option value='fullName'>Full name (Shopify)</Option>
//                           <Option value='code'>2 digit code</Option>
//                           <Option value='no'>No</Option>
//                         </Select>
//                       </Form.Item>
//                       <Form.Item
//                         name='desktopInlineMarketSelectorCurrency'
//                         label={
//                           <FormLabel
//                             title='Currency'
//                             subTitle='Help text will come here'
//                           />
//                         }
//                       >
//                         <Select>
//                           <Option value='code'>3 digit code</Option>
//                           <Option value='symbol'>Symbol</Option>
//                           <Option value='fullName'>Full name (Shopify)</Option>
//                           <Option value='no'>No</Option>
//                         </Select>
//                       </Form.Item>
//                     </Space>
//                   )}
//                 </Space>
//               </Space>
//             </Radio.Group>
//           </Form.Item>
//         );
//       }}
//     </Form.Item>
//   );
// }

// export default InlineHeader;
