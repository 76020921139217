import axios from 'axios';
import { Row, Col } from 'antd/lib/grid';
import Space from 'antd/lib/space';
import message from 'antd/lib/message';
import Typography from 'antd/lib/typography';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Modal } from 'antd';
import CheckCircleFilled from '@ant-design/icons/lib/icons/CheckCircleFilled';
import MessageBox from './MessageBox';
import pleadingFace from '../../../assets/images/pleading-face.png';
import grinningFace from '../../../assets/images/grinning-face.png';

import classes from './setupCompleted.module.less';
import { useSettingsContext } from '../../../context/SettingsContext';

function SetupCompleted() {
  const navigate = useNavigate();
  const { handleSettingsData } = useSettingsContext();
  const [loading, setLoading] = useState(false);

  // Update isOnboardingCompleted value to true
  const onFinish = () => {
    setLoading(true);
    const data = {
      stepReached: 7,
      isOnboardingCompleted: true,
    };
    axios
      .put('/settings', data)
      .then((res) => {
        handleSettingsData(res.data);
        setLoading(false);
        navigate('/settings');
      })
      .catch(() => {
        message.error('Something went wrong');
        setLoading(false);
      });
  };

  const title = (
    <div className={classes.header}>
      <Space size={16}>
        <CheckCircleFilled className={classes.checkedCircle} />
        <Typography.Text>
          Market selector customization is done!
        </Typography.Text>
      </Space>
    </div>
  );

  return (
    <Modal
      visible
      maskClosable={false}
      title={title}
      footer={null}
      width={800}
      closable={false}
      maskTransitionName={null}
      centered
      mask
    >
      <Space direction='vertical' size={24} className={classes.root}>
        <Space direction='vertical' size={0}>
          <Typography.Text strong className={classes.text}>
            What’s next?
          </Typography.Text>
          <Typography.Text>
            Please open your store now (in incognito tab if possible) and check
            if your market selector is looking as per your settings or not!
          </Typography.Text>
        </Space>
        <Row gutter={[32, 32]}>
          <Col xs={24} md={12}>
            <MessageBox
              description='I have checked and it’s looking perfect.'
              buttonText='Confirm'
              buttonType='primary'
              handleClick={onFinish}
              icon={grinningFace}
              loading={loading}
            />
          </Col>
          <Col xs={24} md={12}>
            <MessageBox
              description='I have checked and I see some issue.'
              buttonText='Contact support'
              buttonType='ghost'
              danger
              icon={pleadingFace}
              htmlType='button'
              handleClick={() => window.$crisp.push(['do', 'chat:open'])}
            />
          </Col>
        </Row>
      </Space>
    </Modal>
  );
}

export default SetupCompleted;
