/* eslint-disable no-plusplus */
import { useEffect, useCallback } from 'react';
import flatten from 'flat';
import $ from 'jquery';
// eslint-disable-next-line no-unused-vars
import select2 from 'select2';

import 'select2/dist/css/select2.min.css';
import '../CountrySelector/select2.css';
import { languages } from '../../utils/Mappings/language';

function LanguageSelector({ formData, device }) {
  const switcherValues = flatten.unflatten(formData);

  // options
  function languageOptions(deviceType) {
    const html = languages.map((language) => (
      <option key={language.id} value={language.id}>
        {switcherValues[deviceType].useOwnLanguage
          ? language.custom[
              switcherValues[deviceType].switcherDropDown.languageSwitcherStyle
            ]
          : language[
              switcherValues[deviceType].switcherDropDown.languageSwitcherStyle
            ]}
      </option>
    ));

    return html;
  }

  // language dropdown selected value markup
  const templateResult = useCallback((state) => {
    if (!state.id) return state.text;

    const $state = $(`<span class="mlveda-result-main">${state.text}</span>`);
    return $state;
  }, []);

  // language dropdown dropdown markup
  const templateSelection = useCallback(
    (state) => {
      if (!state.id) return state.text;

      const language = languages.find((lang) => lang.id === state.id);

      const $state = $(
        `<span class="mlveda-result-main">${
          switcherValues[device].useOwnLanguage
            ? language?.custom[
                switcherValues[device].switcher.languageSwitcherStyle
              ]
            : language[switcherValues[device].switcher.languageSwitcherStyle]
        }</span>`,
      );
      return $state;
    },
    [device, switcherValues],
  );

  // useEffect(
  //   () => () => {
  //     if ($('#languages')?.hasClass('select2-hidden-accessible')) {
  //       $('#languages').select2('destroy');
  //     }
  //   },
  //   [device],
  // );

  useEffect(() => {
    // if ($('#mySelect2').hasClass('select2-hidden-accessible')) {
    //   return;
    // }

    $('#languages').select2({
      templateResult,
      templateSelection,
      dropdownParent: $('#language-selector-wrapper'),
    });

    // remove searchbar from language switcher
    $('#languages').on('select2:open', (e) => {
      $('.select2-search').addClass('no-searchbar');
    });
    $('#languages').on('select2:close', (e) => {
      $('.select2-search').removeClass('no-searchbar');
    });
  }, [templateResult, templateSelection]);

  return (
    <div
      key={device}
      id='language-selector-wrapper'
      style={{ letterSpacing: '0.06rem', textAlign: 'center' }}
    >
      <select
        data-select2-id='language'
        className='language-select no-searchbar'
        name='languages'
        id='languages'
        // defaultValue='en'
      >
        {languageOptions(device)}
      </select>
    </div>
  );
}

export default LanguageSelector;
