import { useEffect, useState } from 'react';
import Layout from 'antd/lib/layout';
import Menu from 'antd/lib/menu';
import Button from 'antd/lib/button';
import { Row, Col } from 'antd/lib/grid';
import Space from 'antd/lib/space';
import Drawer from 'antd/lib/drawer';
import message from 'antd/lib/message';
import Switch from 'antd/lib/switch';
import Modal from 'antd/lib/modal';
import Text from 'antd/lib/typography/Text';
import BarsOutlined from '@ant-design/icons/lib/icons/BarsOutlined';
import SettingFilled from '@ant-design/icons/lib/icons/SettingFilled';
import QuestionCircleFilled from '@ant-design/icons/lib/icons/QuestionCircleFilled';
import WechatFilled from '@ant-design/icons/lib/icons/WechatFilled';
import CloseCircleFilled from '@ant-design/icons/lib/icons/CloseCircleFilled';
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import axios from 'axios';
import Tooltip from 'antd/lib/tooltip';
import Logo from './Logo';
import classes from './index.module.less';
import { useSettingsContext } from '../../../context/SettingsContext';
import { useContainerContext } from '../../../context/ContainerContext';
import ProgressBar from './ProgressBar';

const { Header } = Layout;

const TopBarMenu = function TopBarMenu({
  className,
  onClose,
  isVertical = false,
}) {
  const { pathname } = useLocation();
  const [selectedKey, setSelectedKey] = useState([]);
  useEffect(() => {
    if (pathname.startsWith('/settings')) {
      setSelectedKey(['/settings']);
    } else if (pathname.startsWith('/support')) {
      setSelectedKey(['/support']);
    } else if (pathname.startsWith('/faqs')) {
      setSelectedKey(['/faqs']);
    } else {
      setSelectedKey([]);
    }
  }, [pathname]);

  return (
    <Menu
      data-testid='menubar'
      mode={isVertical ? 'vertical' : 'horizontal'}
      selectedKeys={selectedKey}
      className={classNames(className, {
        [classes.verticalMenu]: isVertical,
      })}
    >
      <Menu.Item icon={<SettingFilled />} key='/settings'>
        <NavLink to='/settings'>Settings</NavLink>
      </Menu.Item>
      <Menu.Item icon={<QuestionCircleFilled />} key='/faqs'>
        <NavLink to='/faqs'>FAQs</NavLink>
      </Menu.Item>
      <Menu.Item icon={<WechatFilled />} key='/support'>
        <NavLink to='/support'>Support</NavLink>
      </Menu.Item>
      {isVertical && (
        <Menu.Item
          onClick={() => onClose?.()}
          icon={<CloseCircleFilled />}
          key='close'
          data-testid='drawerClose'
        >
          <Text>Close</Text>
        </Menu.Item>
      )}
    </Menu>
  );
};

const TopBar = function TopBar({ children }) {
  const [drawerVisible, setDrawerVisible] = useState(false);
  // const [loading, setLoading] = useState(false);
  const { settings, handleSettingsData } = useSettingsContext();
  const { loading, enableLoading, disableLoading } = useContainerContext();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const changeAppSetting = (isAppEnabled) => {
    enableLoading();
    const updatedData = {
      ...settings,
      isAppEnabled,
    };
    axios
      .put('/settings', updatedData)
      .then((res) => {
        handleSettingsData(res.data);
        setIsConfirmModalOpen(false);
        message.success('Saved successfully!');
      })
      .catch((err) => {
        console.error(err);
        message.error('Something went wrong!');
      })
      .finally(() => {
        disableLoading();
      });
  };

  const onSwitchChange = (isAppEnabled) => {
    if (!isAppEnabled) {
      setIsConfirmModalOpen(true);
      // Modal.confirm({
      //   title: 'Do you want to disable app?',
      //   // content: 'Bla bla ...',
      // type: 'warn',
      // okText: 'Confirm',
      // okButtonProps: {
      //   danger: true,
      // },
      // cancelText: 'Cancel',
      // onOk: () => changeAppSetting(isAppEnabled),
      // });
      return;
    }
    // noinspection JSIgnoredPromiseFromCall
    changeAppSetting(isAppEnabled);
  };

  const handleCancel = () => {
    setIsConfirmModalOpen(false);
  };

  // useEffect(() => {
  //   disableLoading();
  // }, [settings]);

  const onDrawerClose = () => setDrawerVisible(false);
  const rowGutter = [10];
  const barsIcon = <BarsOutlined />;

  return [
    <Header key='header' className={classes.header}>
      <Row
        gutter={rowGutter}
        justify='start'
        align='middle'
        className={classes.logoContainer}
        wrap={false}
      >
        {settings?.isOnboardingCompleted && (
          <Col className='pcHidden' style={{ paddingLeft: 0 }}>
            <Button
              data-testid='burgerBtn'
              type='primary'
              icon={barsIcon}
              onClick={() => setDrawerVisible(true)}
              className={classes.barButton}
            />
          </Col>
        )}
        <Col className={classes.logoCol}>
          <Logo />
        </Col>
      </Row>

      {settings?.isOnboardingCompleted ? (
        <TopBarMenu
          className={classNames(classes.menuWrapper, 'mobileHidden')}
        />
      ) : (
        <ProgressBar stepReached={settings?.stepReached} />
      )}

      <div className={classes.emptyStatus} />
      {settings?.isOnboardingCompleted ? (
        <Space>
          {children}
          <Row wrap={false} gutter={10} justify='center'>
            <Col>
              <Text className={classes.appStatusText}>App Status</Text>
            </Col>
            <Col>
              <Switch
                data-testid='appSwitch'
                loading={loading}
                checked={settings.isAppEnabled}
                onChange={onSwitchChange}
              />
            </Col>
          </Row>
        </Space>
      ) : (
        <Tooltip
          placement='bottomLeft'
          title={
            <span>
              Need installation help? Please contact us and we will revert
              within one business day.
            </span>
          }
        >
          <Button
            type='link'
            onClick={() => window.$crisp.push(['do', 'chat:open'])}
          >
            Help
          </Button>
        </Tooltip>
      )}
    </Header>,
    settings?.isOnboardingCompleted && (
      <Drawer
        data-testid='drawer'
        key='drawer'
        placement='left'
        closable={false}
        visible={drawerVisible}
        className={classes.drawer}
        onClose={onDrawerClose}
      >
        <TopBarMenu isVertical onClose={onDrawerClose} />
      </Drawer>
    ),
    isConfirmModalOpen && (
      <Modal
        key='modal'
        closable={false}
        visible
        maskClosable={false}
        okText='Confirm'
        okButtonProps={{
          danger: true,
          loading,
        }}
        cancelText='Cancel'
        onCancel={handleCancel}
        cancelButtonProps={{
          disabled: loading,
        }}
        onOk={() => changeAppSetting(false)}
        width={416}
      >
        <Space size={16} direction='horizontal' wrap={false}>
          <ExclamationCircleOutlined className={classes.exclaimationIcon} />
          <Text className={classes.confirmText}>
            Do you want to disable the app?
          </Text>
        </Space>
      </Modal>
    ),
  ];
};

export default TopBar;
