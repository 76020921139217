import Space from 'antd/lib/space';
import Card from 'antd/lib/card';
import Form from 'antd/lib/form';
import Button from 'antd/lib/button';
import Typography from 'antd/lib/typography';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import CountrySelector from '../CountrySelector';
import LanguageSelector from './LanguageSelector';

import classes from './languageSwitcher.module.less';

const { Text } = Typography;

function LanguageSwitcherPreview({ device }) {
  return (
    <div className={classes.preview}>
      <Card extra={<CloseOutlined className={classes.crossIcon} />}>
        <Space direction='vertical' wrap={false} size={16}>
          <Form.Item label={<Text strong>Country</Text>}>
            <CountrySelector device={device} />
          </Form.Item>
          <Form.Item
            label={<Text strong>Language</Text>}
            dependencies={[
              `${device}.switcher.languageSwitcherStyle`,
              `${device}.switcherDropDown.languageSwitcherStyle`,
              `${device}.useOwnLanguage`,
              `${device}.showLanguageSwitcher`,
            ]}
          >
            {(form) => (
              <LanguageSelector
                formData={form.getFieldsValue(true)}
                device={device}
              />
            )}
          </Form.Item>
          {/* <Form.Item
            noStyle
            dependencies={[
              `${device}.switcher.languageSwitcherStyle`,
              `${device}.switcherDropDown.languageSwitcherStyle`,
              `${device}.useOwnLanguage`,
            ]}
          >
            {(form) => {
              const data = languages.find((language) => language.id === 'en');
              const defaultValue = {
                label: form.getFieldValue(`${device}.useOwnLanguage`)
                  ? data.custom[
                      form.getFieldValue(
                        `${device}.switcher.languageSwitcherStyle`,
                      )
                    ]
                  : data[
                      form.getFieldValue(
                        `${device}.switcher.languageSwitcherStyle`,
                      )
                    ],
                value: data.id,
              };

              const options = languages.map((language) => ({
                label: form.getFieldValue(`${device}.useOwnLanguage`)
                  ? language.custom[
                      form.getFieldValue(
                        `${device}.switcherDropDown.languageSwitcherStyle`,
                      )
                    ]
                  : language[
                      form.getFieldValue(
                        `${device}.switcherDropDown.languageSwitcherStyle`,
                      )
                    ],
                value: language.id,
              }));
              return (
                <Select
                  labelInValue
                  defaultValue={defaultValue.label}
                  onChange={(val) => {
                    const language = languages.find(
                      (lang) => lang.id === val.value,
                    );
                    const switcher = form.getFieldValue(
                      `${device}.switcher.languageSwitcherStyle`,
                    );

                    val.label = language[switcher];
                    return val;
                  }}
                  options={options}
                />
              );
            }}
          </Form.Item> */}
          <Form.Item
            dependencies={[
              `${device}.buttonText`,
              `${device}.buttonTextColor`,
              `${device}.buttonBackgroundColor`,
            ]}
          >
            {(form) => (
              <Button
                block
                type='ghost'
                style={{
                  color: form.getFieldValue(`${device}.buttonTextColor`),
                  backgroundColor: form.getFieldValue(
                    `${device}.buttonBackgroundColor`,
                  ),
                }}
                className={classes.btn}
              >
                {form.getFieldValue(`${device}.buttonText`)}
              </Button>
            )}
          </Form.Item>
        </Space>
      </Card>
    </div>
  );
}

export default LanguageSwitcherPreview;
