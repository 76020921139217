import Collapse from 'antd/lib/collapse';
import Form from 'antd/lib/form';
import InputNumber from 'antd/lib/input-number';
import { Row, Col } from 'antd/lib/grid';
import Typography from 'antd/lib/typography';
import classes from './SwitcherLocationForm.module.less';
import { usePrefixContext } from '../../context/PrefixContext';
import SliderWithFormatterAndParser from '../SliderWithFormatterAndParser';

const { Text } = Typography;

function AdvanceSection() {
  const prefix = usePrefixContext();

  const pxFormatter = (value) => `${value}px`;
  const pxParser = (formattedValue) => +formattedValue.split('px')[0];

  const getSlider = (name) => (
    <Form.Item noStyle name={name}>
      <SliderWithFormatterAndParser
        min={0}
        max={40}
        step={5}
        tipFormatter={pxFormatter}
        formatter={pxFormatter}
        parser={pxParser}
      />
    </Form.Item>
  );
  const getSliderInput = (key, form) => (
    <Form.Item dependencies={[key]}>
      {() => (
        <InputNumber
          className={classes.sliderPostfixInput}
          disabled
          value={form.getFieldValue(key)}
        />
      )}
    </Form.Item>
  );

  return (
    <div key='advanced-section' className={classes.advancedSectionContainer}>
      <Collapse ghost>
        <Collapse.Panel
          key='advanced'
          header={<span className={classes.advancedText}>Advanced</span>}
        >
          <Form.Item dependencies={[`${prefix}.innerPosition`]}>
            {(form) => {
              const value = form.getFieldValue(`${prefix}.innerPosition`);

              const isTop = value?.includes('top');
              const isLeft = value?.includes('left');

              const getTopOrBottomMarkup = (key) => (
                <Form.Item
                  key={key}
                  label={
                    <Text strong>Spacing from {isTop ? 'Top' : 'Bottom'}</Text>
                  }
                >
                  <Row gutter={10}>
                    <Col span={20}>
                      {isTop
                        ? getSlider(`${prefix}.positionTop`)
                        : getSlider(`${prefix}.positionBottom`)}
                    </Col>
                    <Col span={4}>
                      {isTop
                        ? getSliderInput(`${prefix}.positionTop`, form)
                        : getSliderInput(`${prefix}.positionBottom`, form)}
                    </Col>
                  </Row>
                </Form.Item>
              );
              const getLeftOrRightMarkup = (key) => (
                <Form.Item
                  key={key}
                  label={
                    <Text strong>Spacing from {isLeft ? 'Left' : 'Right'}</Text>
                  }
                >
                  <Row gutter={10}>
                    <Col span={20}>
                      {isLeft
                        ? getSlider(`${prefix}.positionLeft`)
                        : getSlider(`${prefix}.positionRight`)}
                    </Col>
                    <Col span={4}>
                      {isLeft
                        ? getSliderInput(`${prefix}.positionLeft`, form)
                        : getSliderInput(`${prefix}.positionRight`, form)}
                    </Col>
                  </Row>
                </Form.Item>
              );

              return [
                isTop && getTopOrBottomMarkup('top'),
                !isTop && getTopOrBottomMarkup('bottom'),
                isLeft && getLeftOrRightMarkup('left'),
                !isLeft && getLeftOrRightMarkup('right'),
              ];
            }}
          </Form.Item>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
}

export default AdvanceSection;
