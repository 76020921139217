/* eslint-disable no-nested-ternary */
import Space from 'antd/lib/space';
import LanguageSelector from '../SmartSelectorSwitch/ToggleLanguageSelector';
import AutoMarketSelector from '../SmartSelectorSwitch/ToggleMarketSelector';
import { useSettingsContext } from '../../context/SettingsContext';

function AutoGeoLocation() {
  const { settings } = useSettingsContext();

  return (
    <Space
      direction='vertical'
      size={0}
      className='autoGeolocation'
      style={{ width: '100%' }}
    >
      <AutoMarketSelector />

      {settings.isOnboardingCompleted ? (
        <LanguageSelector />
      ) : settings.desktop.showLanguageSwitcher ? (
        <LanguageSelector />
      ) : null}
      {/* {!settings.isOnboardingCompleted && (
        <Typography.Paragraph className={classes.paragraph}>
          If you are facing any problem then please{' '}
          <Typography.Link>contact us</Typography.Link> and we will revert back
          you within 24 hours.
        </Typography.Paragraph>
      )} */}
    </Space>
  );
}

export default AutoGeoLocation;
