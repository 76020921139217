import { useState } from 'react';
import Space from 'antd/lib/space';
import Typography from 'antd/lib/typography';
import Modal from 'antd/lib/modal/Modal';
// import Collapse from 'antd/lib/collapse';
import Image from 'antd/lib/image';
import axios from 'axios';
import Spin from 'antd/lib/spin';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import Button from 'antd/lib/button';
import ValidationMessage from './ValidationMessage';
import ModalTitle from '../../../components/ModalTitle';
import deepLink1 from '../../../assets/images/AppEmbed.png';
// import deepLink2 from '../../../assets/images/DeepLink2.png';
import { useShopContext } from '../../../context/ShopContext';

import classes from './appInstallMessage.module.less';
import { useSettingsContext } from '../../../context/SettingsContext';

// const { Panel } = Collapse;
const { Text, Link } = Typography;

// onSave function will always be async for this component
function AppInstallMessage({ onSave, onClose, closable = false }) {
  const shops = useShopContext();
  const { settings } = useSettingsContext();
  const [loading, setLoading] = useState(false);
  const [isValidationModalOpen, setIsValidationModalOpen] = useState(false);
  const [isValidated, setIsValidated] = useState(false);

  // App embed url
  let appEmbedUrl = '';
  // production app url (shopify app)
  if (process.env.REACT_APP_ENVIORNMENT === 'production') {
    appEmbedUrl = `https://${shops?.myshopify_domain}/admin/themes/current/editor?context=apps&activateAppId=a0c601f6-9804-4907-9fbe-72ea549c04ee/mlveda-market-head`;
  }
  // development app url
  else {
    appEmbedUrl = `https://${shops?.myshopify_domain}/admin/themes/current/editor?context=apps&activateAppId=7aa53017-9e78-4a45-b0c8-c03b7da3124a/mlveda-market-head`;
  }

  // check for app is enabled or not from shopify themes
  const handleValidation = () => {
    setLoading(true);
    axios
      .get('/embedded-app')
      .then((res) => {
        setIsValidated(res.data.appEmbedStatus);
        setIsValidationModalOpen(true);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  // handle success modal primary button
  const handlePrimaryBtn = () => {
    if (isValidated) {
      setLoading(true);

      // async call from parent element
      onSave()
        .then(() => {
          setIsValidationModalOpen(false);
        })
        .finally(() => setLoading(false));
    } else {
      setIsValidationModalOpen(false);
    }
  };

  const subTitle = (
    <div>
      <Text>Awesome! GeoFly app is live on your store now.</Text>
      {!settings.isOnboardingCompleted && (
        <Text style={{ display: 'block' }}>
          You can use our default settings or change them if you wish!
        </Text>
      )}
    </div>
  );

  return [
    <Modal
      key='validation-modal'
      visible
      title={
        <ModalTitle
          title={
            settings.isOnboardingCompleted
              ? 'GeoFly app is disabled on your store'
              : 'Add GeoFly app to your store'
          }
          description='Follow the below two steps.'
        />
      }
      centered
      closable={closable}
      maskTransitionName={null}
      maskClosable={false}
      maskStyle={{ backgroundColor: '#36363678' }}
      className={classes.modal}
      // onOk={handleValidation}
      // okText='Validate the integration'
      // okButtonProps={{ loading }}
      // cancelText='Close'
      // onCancel={onClose}
      // cancelButtonProps={{ disabled: loading }}
      footer={
        <Button type='primary' onClick={handleValidation} loading={loading}>
          Add app
        </Button>
      }
      bodyStyle={{ maxHeight: 350, overflowY: 'auto' }}
    >
      <Spin spinning={loading} indicator={<LoadingOutlined />}>
        <Space direction='vertical' size={12} className={classes.container}>
          <ol className={classes.list}>
            <li>
              Click{' '}
              <Link underline target='_blank' href={appEmbedUrl}>
                here
              </Link>{' '}
              (It will open in another tab)
            </li>
            <li>
              Click on Save button (See on the top right corner of the page)
            </li>
            <Image src={deepLink1} preview={false} />
          </ol>
          <div className={classes.card}>
            {/* <Text>
            If you are getting any error not seeing save button the above page
            then follow the details steps given below.
          </Text> */}
            {/* <Collapse accordion>
              <Panel header='Facing any issues? Follow the below detailed steps'>
                <Space direction='vertical' size={24}>
                  <ol className={classes.list}>
                    <li>
                      Click{' '}
                      <Link underline target='_blank' href={appEmbedUrl}>
                        here
                      </Link>{' '}
                      (It will open in another tab)
                    </li>
                    <li>
                      Search for &quot;GeoFly Lang & Market Selector&quot; on
                      the left bar as shown below
                    </li>
                    <Image src={deepLink2} preview={false} height={128} />
                    <li>Now click on save button at the top right</li>
                    <Image src={deepLink1} preview={false} />
                  </ol>
                  <Typography.Text>
                    If you are facing any problem then please{' '}
                    <Typography.Link
                      onClick={() => window.$crisp.push(['do', 'chat:open'])}
                    >
                      contact us{' '}
                    </Typography.Link>
                    and we will revert within 24 hours
                  </Typography.Text>
                </Space>
              </Panel>
            </Collapse> */}
            <Typography.Text>
              If you are facing any problem then please{' '}
              <Typography.Link
                onClick={() => window.$crisp.push(['do', 'chat:open'])}
              >
                contact us{' '}
              </Typography.Link>
              and we will revert within 24 hours
            </Typography.Text>
          </div>
        </Space>
      </Spin>
    </Modal>,
    isValidationModalOpen && (
      <ValidationMessage
        key='status-modal'
        status={isValidated ? 'success' : 'warning'}
        closable={false}
        title={
          isValidated ? (
            <Text strong>{`Congratulations ${shops?.name}!`}</Text>
          ) : (
            <Text
              strong
              style={{ color: 'red', display: 'block', textAlign: 'center' }}
            >
              Setup failed
            </Text>
          )
        }
        subTitle={
          isValidated ? subTitle : 'Make sure you clicked on the save button'
        }
        loading={loading}
        primaryBtnText={
          isValidated ? (
            <span>
              {settings.isOnboardingCompleted ? (
                <span>Done</span>
              ) : (
                <span>
                  Customise now <i className='las la-arrow-right' />
                </span>
              )}
            </span>
          ) : (
            'Back'
          )
        }
        secondaryBtnText={isValidated ? null : 'Contact us'}
        handleSecondaryBtn={
          isValidated ? null : () => window.$crisp.push(['do', 'chat:open'])
        }
        handlePrimaryBtn={handlePrimaryBtn}
      />
    ),
  ];
}

export default AppInstallMessage;
