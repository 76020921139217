import Image from 'antd/lib/image';
import Space from 'antd/lib/space';
import Typography from 'antd/lib/typography';
import Button from 'antd/lib/button';
import Card from 'antd/lib/card';
import classes from './setupCompleted.module.less';

function MessageBox({
  description,
  icon,
  buttonText,
  loading,
  danger = false,
  buttonType = 'primary',
  htmlType = 'button',
  handleClick,
}) {
  return (
    <Card className={classes.messageBox}>
      <Space direction='vertical'>
        <Image src={icon} preview={false} width={32} height={32} />
        <Typography.Text strong>{description}</Typography.Text>
        <Button
          type={buttonType}
          danger={danger}
          htmlType={htmlType}
          className={classes.button}
          loading={loading}
          onClick={handleClick}
        >
          {buttonText}
        </Button>
      </Space>
    </Card>
  );
}

export default MessageBox;
