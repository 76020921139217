import Space from 'antd/lib/space';
import Form from 'antd/lib/form';
import PageHeader from 'antd/lib/page-header';
import flatten from 'flat';
import Spin from 'antd/lib/spin';
import message from 'antd/lib/message';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import { PrefixContextProvider } from '../../../context/PrefixContext';
import { useSettingsContext } from '../../../context/SettingsContext';

/**
 * ? Layout for all the steps of the onboarding
 */

function StepPageLayout({ title, subTitle, spinning, handleFinish, children }) {
  const [form] = Form.useForm();
  const { settings } = useSettingsContext();

  // handle next/finish button click
  const onFinish = () => {
    handleFinish(form);
  };

  const onFinishFailed = () => {
    message.error('Please fill required fields');
  };

  return (
    <Spin spinning={spinning} indicator={<LoadingOutlined />}>
      <div className='container'>
        <PageHeader
          className='page-header'
          title={
            <Space direction='vertical' gap={0}>
              <h5 className='ant-page-header-heading-title'>{title}</h5>
              <span className='ant-page-header-heading-sub-title'>
                {subTitle}
              </span>
            </Space>
          }
        />

        <div className='section'>
          {/* For the onboarding part prefix will be desktop only */}
          <PrefixContextProvider value='desktop'>
            <div className='form-section'>
              <Form
                form={form}
                layout='vertical'
                initialValues={flatten(settings)}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                onValuesChange={() => form.validateFields()}
                scrollToFirstError
              >
                {children}
              </Form>
            </div>
          </PrefixContextProvider>
        </div>
      </div>
    </Spin>
  );
}

export default StepPageLayout;
