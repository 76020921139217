import Radio from 'antd/lib/radio';
import Form from 'antd/lib/form';
import Typography from 'antd/lib/typography';
import Image from 'antd/lib/image';
// import { Row, Col } from 'antd/lib/grid';
// import InputNumber from 'antd/lib/input-number';
// import Slider from 'antd/lib/slider';
// import Collapse from 'antd/lib/collapse';
import Space from 'antd/lib/space';

import classes from './SwitcherLocationForm.module.less';
import image from '../../assets/images/switcher-fixed-location-background.png';
import { usePrefixContext } from '../../context/PrefixContext';

// TODO: change href to make shop name dynamic

const { Text } = Typography;

/* eslint-disable react/no-unescaped-entities */
const FixedPositionPanelContent = function FixedPositionPanelContent() {
  const prefix = usePrefixContext();
  return (
    <Space direction='vertical' size={12} style={{ width: '100%' }}>
      <Text key='text' strong>
        Choose the position:
      </Text>
      {/* // <ul key='text'>
    //   <li className={classes.inlineContentPanelLi}>
    //     Switcher will be fixed always at a selected position even on page
    //     scroll.
    //   </li>
    //   <li className={classes.inlineContentPanelLi}>
    //     If you are not satisfied by the look and feel of the switcher, please
    //     drop us an email at{' '}
    //       <Link href='mailto:support@mlveda.com'>
                          support@mlveda.com
                        </Link>
    //     . We will do the needful and customize the switcher as per your needs
    //     for free. You can disable the switcher from the top right of this page
    //     in the mean time.
    //   </li>
    // </ul>, */}
      <div key='location-selector' className={classes.checkboxGroup__container}>
        <Image preview={false} src={image} />
        <Form.Item name={`${prefix}.innerPosition`} noStyle>
          <Radio.Group className={classes.checkboxGroup}>
            <Radio value='top_with_left'>
              <Text className={classes.radioText}>
                Top
                <br />
                Left
              </Text>
            </Radio>
            <Radio value='top_with_right'>
              <Text className={classes.radioText}>
                Top
                <br />
                Right
              </Text>
            </Radio>
            <Radio value='bottom_with_left'>
              <Text className={classes.radioText}>
                Bottom
                <br />
                Left
              </Text>
            </Radio>
            <Radio value='bottom_with_right'>
              <Text className={classes.radioText}>
                Bottom
                <br />
                Right
              </Text>
            </Radio>
          </Radio.Group>
        </Form.Item>
      </div>
      {/* <div key='advanced-section' className={classes.advancedSectionContainer}>
        <Collapse ghost>
          <Collapse.Panel
            key='advanced'
            header={<span className={classes.advancedText}>Advanced</span>}
          >
            <Form.Item dependencies={['switcherLocation']}>
              {(form) => {
                const value = form.getFieldValue('switcherLocation');
                const isTop = value.includes('top');
                const isLeft = value.includes('left');

                return [
                  <Form.Item
                    key='topOrBottom'
                    label={
                      <Text strong>
                        Spacing from {isTop ? 'Top' : 'Bottom'}
                      </Text>
                    }
                  >
                    <Row gutter={10}>
                      <Col span={20}>
                        {isTop ? (
                          <Form.Item noStyle name='topSpacing' initialValue={0}>
                            <Slider min={0} max={40} step={5} />
                          </Form.Item>
                        ) : (
                          <Form.Item
                            noStyle
                            name='bottomSpacing'
                            initialValue={0}
                          >
                            <Slider min={0} max={40} step={5} />
                          </Form.Item>
                        )}
                      </Col>
                      <Col span={4}>
                        {isTop ? (
                          <Form.Item dependencies={['topSpacing']}>
                            {() => (
                              <InputNumber
                                className={classes.sliderPostfixInput}
                                disabled
                                value={`${form.getFieldValue('topSpacing')}px`}
                              />
                            )}
                          </Form.Item>
                        ) : (
                          <Form.Item dependencies={['bottomSpacing']}>
                            {() => (
                              <InputNumber
                                className={classes.sliderPostfixInput}
                                disabled
                                value={`${form.getFieldValue(
                                  'bottomSpacing',
                                )}px`}
                              />
                            )}
                          </Form.Item>
                        )}
                      </Col>
                    </Row>
                  </Form.Item>,
                  <Form.Item
                    key='leftOrRight'
                    label={
                      <Text strong>
                        Spacing from {isLeft ? 'Left' : 'Right'}
                      </Text>
                    }
                  >
                    <Row gutter={10}>
                      <Col span={20}>
                        {isTop ? (
                          <Form.Item
                            noStyle
                            name='leftSpacing'
                            initialValue={0}
                          >
                            <Slider min={0} max={40} step={5} />
                          </Form.Item>
                        ) : (
                          <Form.Item
                            noStyle
                            name='rightSpacing'
                            initialValue={0}
                          >
                            <Slider min={0} max={40} step={5} />
                          </Form.Item>
                        )}
                      </Col>
                      <Col span={4}>
                        {isTop ? (
                          <Form.Item dependencies={['leftSpacing']}>
                            {() => (
                              <InputNumber
                                className={classes.sliderPostfixInput}
                                disabled
                                value={`${form.getFieldValue('leftSpacing')}px`}
                              />
                            )}
                          </Form.Item>
                        ) : (
                          <Form.Item dependencies={['rightSpacing']}>
                            {() => (
                              <InputNumber
                                className={classes.sliderPostfixInput}
                                disabled
                                value={`${form.getFieldValue(
                                  'rightSpacing',
                                )}px`}
                              />
                            )}
                          </Form.Item>
                        )}
                      </Col>
                    </Row>
                  </Form.Item>,
                ];
              }}
            </Form.Item>
          </Collapse.Panel>
        </Collapse>
      </div> */}
    </Space>
  );
};

export default FixedPositionPanelContent;
