import axios from 'axios';
import Space from 'antd/lib/space';
import { Col, Row } from 'antd/lib/grid';
import Button from 'antd/lib/button';
import message from 'antd/lib/message';
import { unflatten } from 'flat';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import CountrySelector from '../../../components/CountrySelector';
import MarketSelectorDropdown from '../../../components/MarketSelectorDropdown';
import { usePrefixContext } from '../../../context/PrefixContext';
import { useAppContext } from '../../../context/AppContext';
import { useSettingsContext } from '../../../context/SettingsContext';
import StepPageLayout from '../StepPageLayout';

function Step2() {
  const navigate = useNavigate();
  const prefix = usePrefixContext();
  const { handleSettingsData } = useSettingsContext();
  const { designs } = useAppContext();
  const [nextBtnLoading, setNextBtnLoading] = useState(false);
  const [backBtnLoading, setBackBtnLoading] = useState(false);

  const handleFinish = (form) => {
    setNextBtnLoading(true);
    const data = { ...unflatten(form.getFieldsValue(true)), stepReached: 4 };
    axios
      .put('/settings', data)
      .then((res) => {
        handleSettingsData(res.data);
        setNextBtnLoading(false);
        navigate('/step-4');
      })
      .catch(() => {
        message.error('Something went wrong');
        setNextBtnLoading(false);
      });
  };

  const handleBack = () => {
    setBackBtnLoading(true);
    const data = { stepReached: 2 };
    axios
      .put('/settings', data)
      .then((res) => {
        handleSettingsData(res.data);
        setBackBtnLoading(false);
        navigate('/step-2');
      })
      .catch(() => {
        message.error('Something went wrong');
        setBackBtnLoading(false);
      });
  };

  return (
    <StepPageLayout
      handleFinish={handleFinish}
      spinning={nextBtnLoading || backBtnLoading}
      title='Step 3: Dropdown design'
      subTitle='Here, choose the look and feel of the market selector dropdown'
    >
      <Row className='stepContainer'>
        <Col xs={24} md={16} className='leftSection'>
          <MarketSelectorDropdown designs={designs} />
        </Col>
        <Col xs={24} md={8} className='rightSection'>
          <Space direction='vertical' style={{ width: '100%' }}>
            <div className='previewText'>Preview</div>
            <CountrySelector device={prefix} />
          </Space>
        </Col>
        <Col xs={24} md={16}>
          <div className='save-btn-wrapper'>
            <Space align='center' size={24}>
              <Button
                style={{ width: 85 }}
                loading={backBtnLoading}
                onClick={handleBack}
              >
                Back
              </Button>
              <Button
                type='primary'
                htmlType='submit'
                style={{ width: 170 }}
                loading={nextBtnLoading}
              >
                Next
                <i className='las la-arrow-right' />
              </Button>
            </Space>
          </div>
        </Col>
      </Row>
    </StepPageLayout>
  );
}

export default Step2;
