import Typography from 'antd/lib/typography';
import Tooltip from 'antd/lib/tooltip';
import QuestionCircleOutlined from '@ant-design/icons/lib/icons/QuestionCircleOutlined';

import classes from './ModalTitle.module.less';

const { Text, Title } = Typography;

const ModalTitle = function ModalTitle({
  title,
  description,
  helpText = null,
}) {
  return (
    <div>
      <div>
        <Title
          level={5}
          style={{ fontSize: 18, marginBottom: 0, lineHeight: 1.4 }}
        >
          {title}{' '}
          {helpText && (
            <Tooltip title={helpText}>
              <QuestionCircleOutlined className={classes.questionIcon} />
            </Tooltip>
          )}
        </Title>
      </div>
      {description && (
        <Text
          // type='secondary'
          style={{ whiteSpace: 'break-spaces', fontSize: 15 }}
          className={classes.description}
        >
          {description}
        </Text>
      )}
    </div>
  );
};

export default ModalTitle;
