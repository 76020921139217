/* eslint-disable func-names */
import axios from 'axios';
import './utils/axios';
import {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, Route, Routes } from 'react-router-dom';
import message from 'antd/lib/message';
import Result from 'antd/lib/result';
import Button from 'antd/lib/button';
import Spin from 'antd/lib/spin';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import Typography from 'antd/lib/typography';
import { SettingsContextProvider } from './context/SettingsContext';
import LoadingPage from './components/LoadingPage';
import ContainerLayout from './containers/PageLayout/ContainerLayout';
import TopBar from './containers/PageLayout/TopBar';
import ProtectedRoute, {
  ProtectedRouteOnBording,
} from './components/ProtectedRoute';
import { ShopContextProvider } from './context/ShopContext';
import Step1 from './containers/Onboarding/Step1';
import { AppContextProvider } from './context/AppContext';
import Step2 from './containers/Onboarding/Step2';
import Step3 from './containers/Onboarding/Step3';
import Step4 from './containers/Onboarding/Step4';
import Step5 from './containers/Onboarding/Step5';
import AppInstallMessage from './containers/Onboarding/AppInstallMessage';

// Lazy loading components
const Settings = lazy(() => import('./containers/Settings'));
const Support = lazy(() => import('./containers/Support'));
const FaqsPage = lazy(() => import('./containers/FaqsPage'));
const AdvancedCssPage = lazy(() => import('./containers/Settings/AdvancedCss'));
const AutoDetectionPage = lazy(() =>
  import('./containers/Settings/AutoDetect'),
);
const LanguageSwitcherPage = lazy(() =>
  import('./containers/Settings/LanguageSwitcherPage'),
);
const NotShippingCountriesPage = lazy(() =>
  import('./containers/Settings/NotShippingCountries'),
);
const Welcome = lazy(() => import('./containers/Onboarding/Welcome'));
const MarketSelectorPage = lazy(() =>
  import('./containers/Settings/MarketSelectorPage'),
);
const MarketSelectorDropdownPage = lazy(() =>
  import('./containers/Settings/MarketSelectorDropdownPage'),
);
const NotFoundPage = lazy(() => import('./containers/NotFound'));
const ErrorPage = lazy(() => import('./containers/ErrorPage'));

// Default spin
Spin.setDefaultIndicator(<LoadingOutlined />);

const { Text } = Typography;

// Redirect to specific url based on steps (for onboarding) and to the settings page (onboarding is completed)
function RedirectRoute({ stepReached, isOnboardingCompleted }) {
  if (isOnboardingCompleted) {
    return <Navigate to='/settings' />;
  }
  switch (stepReached) {
    case 0: {
      return <Navigate to='/welcome' />;
    }
    case 1: {
      return <Navigate to='/step-1' />;
    }
    case 2: {
      return <Navigate to='/step-2' />;
    }
    case 3: {
      return <Navigate to='/step-3' />;
    }
    case 4: {
      return <Navigate to='/step-4' />;
    }
    case 5: {
      return <Navigate to='/step-5' />;
    }
    default: {
      return <Navigate to='/welcome' />;
    }
  }
}

function App() {
  const [isSessionValid, setIsSessionValid] = useState(true);
  const [settings, setSettings] = useState({});
  const [shops, setShops] = useState({});
  const [designs, setDesigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showNotIntegratedModal, setShowNotIntegratedModal] = useState(false);

  // axios configs => to get url params value by thier name.
  const getParameterByName = (name, url) => {
    if (!url) url = window.location.href;

    name = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);

    if (!results) return null;

    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  useEffect(() => {
    setLoading(true);
    // Check Session Token
    const token = getParameterByName('token');

    // "token" key not exist in the session storage set the token in it.
    if (token != null) {
      sessionStorage.setItem('token', token);
    }
    // if "token" key exist then check its value
    else if (
      process.env.REACT_APP_ENVIORNMENT !== 'local' &&
      !sessionStorage.getItem('token')
    ) {
      setIsSessionValid(false);
      // eslint-disable-next-line no-useless-return
      return;
    }

    // handle axios response interceptor
    axios.interceptors.response.use(
      (response) => response,
      (error) =>
        new Promise((resolve, reject) => {
          if (error.response && error.response.status === 401) {
            // Set session false when token expires and pass promise as resolved
            setIsSessionValid(false);
            resolve(error);
          }

          // if status code is diffrent then 401 then simply return the error.
          // reject will e thrown to the catch block of the function's promise chaining.
          reject(error);
        }),
    );

    // Fetch main (static) api's and store thier responses to the respective states.
    Promise.all([
      axios.get('/settings'),
      axios.get('/shops'),
      axios.get('/designs'),
    ])
      .then(([settingsResponse, shopsResponse, designsResponse]) => {
        setSettings(settingsResponse.data); // stores settings api response
        setShops(shopsResponse.data); // stores shops api response
        setDesigns(designsResponse.data); // stores design api response

        // if onboarding is completed
        // then call the app embedded api to check
        // app is enabled from the shopify or not
        if (settingsResponse?.data?.isOnboardingCompleted) {
          axios
            .get('/embedded-app')
            .then((res) => {
              // if appEmbedStatus is true then dont show the modal
              // else show the not intergrated modal (<AppInstallMessage /> component)
              setShowNotIntegratedModal(!res.data.appEmbedStatus);
            })
            .catch((err) => {
              console.error(err);
              message.err('Something went wrong');
            });
        }

        // Live chat support integration (CRISP)
        const shopName = shopsResponse?.data?.myshopify_domain;

        // script for chat icons

        window.$crisp = [];

        window.CRISP_WEBSITE_ID = '9a70b14e-b8db-48b2-8773-95eb66b91cee';

        (function () {
          const d = document;

          const s = d.createElement('script');

          s.src = 'https://client.crisp.chat/l.js';

          s.async = 1;

          d.getElementsByTagName('head')[0].appendChild(s);

          window.$crisp.push([
            'set',
            'session:segments',
            [['market-selector']],
          ]);

          window.$crisp.push(['set', 'session:data', ['storename', shopName]]);
        })();
      })
      .catch(() => {
        message.error('Something went wrong');

        // Live chat support "Crisp" Error handling
        window.$crisp = [];

        window.CRISP_WEBSITE_ID = '9a70b14e-b8db-48b2-8773-95eb66b91cee';

        (function () {
          const d = document;

          const s = d.createElement('script');

          s.src = 'https://client.crisp.chat/l.js';

          s.async = 1;

          d.getElementsByTagName('head')[0].appendChild(s);

          window.$crisp.push([
            'set',
            'session:segments',
            [['market-selector']],
          ]);
        })();
      })
      .finally(() => setLoading(false));
  }, []);

  // Hide not integration modal (<AppInstallMessage /> component)
  const hideNotIntegratedModal = () => {
    setShowNotIntegratedModal(false);
  };

  /**
   * handle success modal
   * ? component requires async function as prop
   */
  const handleValidationSuccess = async () => setShowNotIntegratedModal(false);

  // update setting's data
  const handleSettingsData = useCallback((data) => {
    setSettings(data);
  }, []);

  // SettingsContext's values
  const value = useMemo(
    () => ({ settings, handleSettingsData }),
    [handleSettingsData, settings],
  );

  // eslint-disable-next-line no-nested-ternary
  return loading ? (
    <LoadingPage />
  ) : // if session is valid then render routes else show invalid session component
  isSessionValid ? (
    <div>
      <SettingsContextProvider value={value}>
        <ShopContextProvider value={shops}>
          <AppContextProvider value={{ designs }}>
            <Suspense fallback={<LoadingPage />}>
              <Routes>
                <Route
                  path='/welcome'
                  element={
                    <ErrorBoundary FallbackComponent={ErrorPage}>
                      <ProtectedRouteOnBording settings={settings}>
                        <Welcome />
                      </ProtectedRouteOnBording>
                    </ErrorBoundary>
                  }
                />
                {/* TODO: add tests for not allowing access to on-boarding routes */}
                <Route
                  path='/'
                  element={
                    <ErrorBoundary FallbackComponent={ErrorPage}>
                      <ContainerLayout topBar={<TopBar />} />
                    </ErrorBoundary>
                  }
                >
                  {/* On initial render of "/" route redirect user to specific route */}
                  <Route
                    index
                    element={
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <RedirectRoute
                          stepReached={settings?.stepReached}
                          isOnboardingCompleted={
                            settings?.isOnboardingCompleted
                          }
                        />
                      </ErrorBoundary>
                    }
                  />

                  {/* Onboarding routes */}

                  <Route
                    path='step-1'
                    element={
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <Step1 />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path='step-2'
                    element={
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <Step2 />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path='step-3'
                    element={
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <Step3 />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path='step-4'
                    element={
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <Step4 />
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path='step-5'
                    element={
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <Step5 />
                      </ErrorBoundary>
                    }
                  />

                  {/* Settings routes */}
                  <Route path='settings'>
                    <Route
                      index
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <ProtectedRoute settings={settings}>
                            <Settings />
                          </ProtectedRoute>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path='market-selector'
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <ProtectedRoute settings={settings}>
                            <MarketSelectorPage />
                          </ProtectedRoute>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path='market-selector-dropdown'
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <ProtectedRoute settings={settings}>
                            <MarketSelectorDropdownPage />
                          </ProtectedRoute>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path='advanced-css'
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <ProtectedRoute settings={settings}>
                            <AdvancedCssPage />
                          </ProtectedRoute>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path='auto-detection'
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <ProtectedRoute settings={settings}>
                            <AutoDetectionPage />
                          </ProtectedRoute>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path='language-switcher'
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <ProtectedRoute settings={settings}>
                            <LanguageSwitcherPage />
                          </ProtectedRoute>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path='not-shipping-countries-message'
                      element={
                        <ErrorBoundary FallbackComponent={ErrorPage}>
                          <ProtectedRoute settings={settings}>
                            <NotShippingCountriesPage />
                          </ProtectedRoute>
                        </ErrorBoundary>
                      }
                    />
                  </Route>
                  {/* Support page route */}
                  <Route path='support' element={<Support />} />

                  {/* Faqs page route */}
                  <Route path='faqs' element={<FaqsPage />} />
                </Route>

                {/* No routes matched */}
                <Route path='*' element={<NotFoundPage />} />
              </Routes>
            </Suspense>
            {showNotIntegratedModal && (
              <AppInstallMessage
                closable={false}
                onSave={handleValidationSuccess}
                onClose={hideNotIntegratedModal}
              />
            )}
          </AppContextProvider>
        </ShopContextProvider>
      </SettingsContextProvider>
    </div>
  ) : (
    // Invalid session component
    <Result
      status='403'
      title={<Text strong>Session expired</Text>}
      subTitle={
        <Text>
          Your session expired. Please go to apps and open the app again.
        </Text>
      }
      extra={
        shops?.myshopify_domain ? (
          <Button
            href={`https://${shops?.myshopify_domain}/admin/apps`}
            target='_blank'
            type='primary'
          >
            Redirect
          </Button>
        ) : null
      }
    />
  );
}

export default App;
