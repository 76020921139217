import Typography from 'antd/lib/typography';

const { Text } = Typography;

function DontShow() {
  return (
    <ul>
      <li>
        <Text>
          In this option, market selector will not be displayed on your store.
        </Text>
      </li>
      <li>
        <Text>
          The market will change automatically as per customer&apos;s country.
        </Text>
      </li>
      {/* <li>
        <Text>We suggest to use this only if you are in paid plan.</Text>
      </li> */}
    </ul>
  );
}

export default DontShow;
