/* eslint-disable no-console */
import axios from 'axios';
import Form from 'antd/lib/form';
import { unflatten } from 'flat';
import message from 'antd/lib/message';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useSettingsContext } from '../../../context/SettingsContext';
import StepPageLayout from '../StepPageLayout';
import Content from './Content';

function Step1() {
  const navigate = useNavigate();
  const { handleSettingsData } = useSettingsContext();

  const [nextBtnLoading, setNextBtnLoading] = useState(false);
  const [backBtnLoading, setBackBtnLoading] = useState(false);

  // Handle Next/Finish Button click
  const handleFinish = (form) => {
    setNextBtnLoading(true);
    /**
     * If do not show (none) position is selected.
     * then just skip all the steps upto auto detection step (upto step 5)
     * else move to next step
     */
    if (form.getFieldValue('desktop.position') === 'none') {
      const data = { ...unflatten(form.getFieldsValue(true)), stepReached: 5 };
      axios
        .put('/settings', data)
        .then((res) => {
          handleSettingsData(res.data);
          setNextBtnLoading(false);
          navigate('/step-5');
        })
        .catch(() => {
          message.error('Something went wrong');
          setNextBtnLoading(false);
        });
    } else {
      const data = { ...unflatten(form.getFieldsValue(true)), stepReached: 2 };
      axios
        .put('/settings', data)
        .then((res) => {
          handleSettingsData(res.data);
          setNextBtnLoading(false);
          navigate('/step-2');
        })
        .catch(() => {
          message.error('Something went wrong');
          setNextBtnLoading(false);
        });
    }
  };

  /**
   * Handle Back button click
   */
  const handleBack = () => {
    setBackBtnLoading(true);
    const data = { stepReached: 0 };
    axios
      .put('/settings', data)
      .then((res) => {
        handleSettingsData(res.data);
        setBackBtnLoading(false);
        navigate('/welcome');
      })
      .catch(() => {
        message.error('Something went wrong');
        setBackBtnLoading(false);
      });
  };

  return (
    <StepPageLayout
      handleFinish={handleFinish}
      spinning={nextBtnLoading || backBtnLoading}
      title='Step 1: Location'
      subTitle='Choose the location of the market selector on your store'
    >
      <Form.Item shouldUpdate={() => false}>
        {(form) => (
          <Content
            form={form}
            nextBtnLoading={nextBtnLoading}
            backBtnLoading={backBtnLoading}
            handleBack={handleBack}
          />
        )}
      </Form.Item>
    </StepPageLayout>
  );
}

export default Step1;
