import Radio from 'antd/lib/radio';
import Button from 'antd/lib/button';
import Typography from 'antd/lib/typography';
import classes from './radioButton.module.less';

function RadioButton({
  text,
  type,
  name,
  value,
  disabled,
  secondaryText,
  block = false,
  handleClick,
}) {
  return (
    <Button
      type={type}
      className={classes.radioBtn}
      onClick={handleClick}
      block={block}
    >
      <Radio
        className={classes.radio}
        name={name}
        value={value}
        disabled={disabled}
      >
        <Typography.Text>{text}</Typography.Text>{' '}
        {secondaryText && (
          <Typography.Link className={classes.secondaryText}>
            {secondaryText}
          </Typography.Link>
        )}
      </Radio>
    </Button>
  );
}

export default RadioButton;
