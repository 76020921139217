import Space from 'antd/lib/space';
import FixedPositionPanelContent from '../SwitcherLocationForm/FixedPositionPanelContent';

import AdvanceSection from '../SwitcherLocationForm/AdvanceSection';
import { useSettingsContext } from '../../context/SettingsContext';

function FixedHeader() {
  const { settings } = useSettingsContext();

  return (
    <Space direction='vertical' size={24} style={{ width: '100%' }}>
      <FixedPositionPanelContent />
      {!settings.isOnboardingCompleted && <AdvanceSection />}
    </Space>
  );
}

export default FixedHeader;

// {/* <Form.Item name='desktopFixedDesignType'>
// <Radio.Group>
//   <Space direction='vertical' size={24}>
//     <Space direction='vertical'>
//       <Radio value='fromDesign'>
//         <Space direction='vertical' size={0}>
//           <Text strong>Choose from existing designs</Text>
//           <Text>Help text will come here</Text>
//         </Space>
//       </Radio>
//       {form.getFieldValue('desktopFixedDesignType') ===
//         'fromDesign' && (
//         <div className='pl-24'>
//           <Form.Item name='selectorDesign'>
//             {/* <Radio.Group> */}
//             <Row gutter={[12, 12]} wrap justify='space-between'>
//               <Col xs={24} md={12}>
//                 <SelectorDesign
//                   selected={selected === 'flagAnd2Code'}
//                   name='flagAnd2Code'
//                   value='flagAnd2Code'
//                   code='IN'
//                   description='flag + 2 digit country code'
//                   handleClick={handleDesignSelect}
//                 />
//               </Col>
//               <Col xs={24} md={12}>
//                 <SelectorDesign
//                   selected={selected === 'flagAnd3Code'}
//                   name='flagAnd3Code'
//                   value='flagAnd3Code'
//                   code='INR'
//                   description='flag + 3 digit country code'
//                   handleClick={handleDesignSelect}
//                 />
//               </Col>
//               <Col xs={24} md={12}>
//                 <SelectorDesign
//                   selected={selected === 'flagAndSymbol'}
//                   name='flagAndSymbol'
//                   value='flagAndSymbol'
//                   symbol='₹'
//                   description='flag + currency symbol'
//                   handleClick={handleDesignSelect}
//                 />
//               </Col>
//               <Col xs={24} md={12}>
//                 <SelectorDesign
//                   selected={selected === 'flagAndCountry'}
//                   name='flagAndCountry'
//                   value='flagAndCountry'
//                   code='India'
//                   description='flag + country name'
//                   handleClick={handleDesignSelect}
//                 />
//               </Col>
//             </Row>
//             {/* </Radio.Group> */}
//           </Form.Item>
//         </div>
//       )}
//     </Space>
//     <Space direction='vertical'>
//       <Radio value='customDesign'>
//         <Space direction='vertical' size={0}>
//           <Text strong>I will create my own design</Text>
//           <Text>Help text will come here</Text>
//         </Space>
//       </Radio>
//       {form.getFieldValue('desktopFixedDesignType') ===
//         'customDesign' && (
//         <Space
//           className='pl-24'
//           direction='vertical'
//           size={12}
//           // className={classes.subSection}
//         >
//           <Form.Item
//             name='desktopFixedMarketSelectorFlag'
//             label={
//               <FormLabel
//                 title='Flag'
//                 subTitle='Help text will come here'
//               />
//             }
//           >
//             <Select>
//               <Option value='rounded'>Rounded</Option>
//               <Option value='rectangular'>Rectangular</Option>
//               <Option value='no'>No</Option>
//             </Select>
//           </Form.Item>
//           <Form.Item
//             name='desktopFixedMarketSelectorCountry'
//             label={
//               <FormLabel
//                 title='Country'
//                 subTitle='Help text will come here'
//               />
//             }
//           >
//             <Select>
//               <Option value='fullName'>
//                 Full name (Shopify)
//               </Option>
//               <Option value='code'>2 digit code</Option>
//               <Option value='no'>No</Option>
//             </Select>
//           </Form.Item>
//           <Form.Item
//             name='desktopFixedMarketSelectorCurrency'
//             label={
//               <FormLabel
//                 title='Currency'
//                 subTitle='Help text will come here'
//               />
//             }
//           >
//             <Select>
//               <Option value='code'>3 digit code</Option>
//               <Option value='symbol'>Symbol</Option>
//               <Option value='fullName'>
//                 Full name (Shopify)
//               </Option>
//               <Option value='no'>No</Option>
//             </Select>
//           </Form.Item>
//         </Space>
//       )}
//     </Space>
//   </Space>
// </Radio.Group>
// </Form.Item> */}
