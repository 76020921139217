import Divider from 'antd/lib/divider';
import SmartSelectorSwitch from '.';

function ToggleLanguageSelector() {
  return (
    <>
      <Divider />
      <SmartSelectorSwitch
        name='languageAutoDetect'
        title='Auto detect language'
        description='Enabling this option lets your customers to browse the store in their
      local language by default. If
      you enable this then your customers do not need to choose the language manually from the
      market selector'
      />
    </>
  );
}

export default ToggleLanguageSelector;
