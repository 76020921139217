import Space from 'antd/lib/space';
import Typography from 'antd/lib/typography';
import Image from 'antd/lib/image';
import logo from '../../../assets/images/logo.png';

import classes from './Logo.module.less';

const { Text } = Typography;

const Logo = function LogoImage() {
  return (
    <Space
      direction='horizontal'
      wrap={false}
      size={8}
      className={classes.logoContainer}
    >
      <Image preview={false} src={logo} className={classes.logo} />
      <div className={classes.brandName}>
        <Text>GeoFly</Text>
        <Text>Language & Market Selector</Text>
      </div>
    </Space>
  );
};

export default Logo;
