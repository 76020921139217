import Form from 'antd/lib/form';
import Select2 from './Select2';

function CountrySelector({ device }) {
  return (
    <Form.Item
      dependencies={[
        `${device}.switcher.currencyStyle`,
        `${device}.switcher.countryStyle`,
        `${device}.switcher.flagStyle`,
        `${device}.switcher.showFlag`,
        `${device}.switcherDropDown.currencyStyle`,
        `${device}.switcherDropDown.countryStyle`,
        `${device}.switcherDropDown.flagStyle`,
        `${device}.switcherDropDown.showFlag`,
        `${device}.countrySearch`,
      ]}
    >
      {({ getFieldsValue, getFieldValue }) => (
        <Select2
          formData={getFieldsValue(true)}
          device={device}
          showSearchBar={getFieldValue(`${device}.countrySearch`)}
        />
      )}
    </Form.Item>
  );
}

export default CountrySelector;
