const countries = [
  {
    text: 'USA',
    name: 'United States',
    id: 'usa',
    iso_code: 'US',
    flag_code: 'US',
    currency: {
      iso_code: 'USD',
      symbol: '$',
      name: 'US Dollar',
    },
  },
  {
    text: 'France',
    name: 'France',
    id: 'fr',
    iso_code: 'FR',
    flag_code: 'FR',
    currency: {
      iso_code: 'USD',
      symbol: '$',
      name: 'US Dollar',
    },
  },
  {
    text: 'Australia',
    name: 'Australia',
    id: 'au',
    iso_code: 'AU',
    flag_code: 'AU',
    currency: {
      iso_code: 'USD',
      symbol: '$',
      name: 'US Dollar',
    },
  },

  {
    text: 'Canada',
    name: 'Canada',
    id: 'ca',
    iso_code: 'CA',
    flag_code: 'CA',
    currency: {
      iso_code: 'USD',
      symbol: '$',
      name: 'US Dollar',
    },
  },
  {
    text: 'Germany',
    name: 'Germany',
    id: 'de',
    iso_code: 'DE',
    flag_code: 'DE',
    currency: {
      iso_code: 'USD',
      symbol: '$',
      name: 'US Dollar',
    },
  },
  {
    text: 'United Kingdom',
    name: 'United Kingdom',
    id: 'gb',
    iso_code: 'GB',
    flag_code: 'GB',
    currency: {
      iso_code: 'USD',
      symbol: '$',
      name: 'US Dollar',
    },
  },
  {
    text: 'Japan',
    name: 'Japan',
    id: 'jp',
    iso_code: 'JP',
    flag_code: 'JP',
    currency: {
      iso_code: 'USD',
      symbol: '$',
      name: 'US Dollar',
    },
  },
  {
    text: 'South Korea',
    name: 'South Korea',
    id: 'kr',
    iso_code: 'KR',
    flag_code: 'KR',
    currency: {
      iso_code: 'USD',
      symbol: '$',
      name: 'US Dollar',
    },
  },
  {
    text: 'India',
    name: 'India',
    id: 'ind',
    iso_code: 'IN',
    flag_code: 'IN',
    currency: {
      iso_code: 'INR',
      symbol: '₹',
      name: 'Indian Rupee',
    },
  },
];

export { countries };
