import { createContext, useContext } from 'react';

const SettingsContext = createContext('mobile');

export const SettingsContextProvider = SettingsContext.Provider;

export const useSettingsContext = () => {
  const settingsData = useContext(SettingsContext);

  if (settingsData == null) {
    throw Error(
      'Please wrap this component in SettingsContextProvider and pass non null value',
    );
  }

  const { settings, handleSettingsData } = settingsData;

  return { settings, handleSettingsData };
};

export default SettingsContext;
