import Form from 'antd/lib/form';
import Select from 'antd/lib/select';
import Space from 'antd/lib/space';
import Radio from 'antd/lib/radio';
import Typography from 'antd/lib/typography';
import { Row, Col } from 'antd/lib/grid';
import SwitcherDesign from '../SwitcherDesign';
import FormLabel from '../FormLabel';

const { Text } = Typography;
const { Option } = Select;

function DesignSection({ designs, form, prefix }) {
  // updates data in the form based on the design selected
  const handleDesignSelect = (newValue) => {
    const active = [...designs].find(
      (design) => design[prefix].designId === newValue,
    );

    const values = {};
    values[`${prefix}.switcher.designId`] = active[prefix].designId;
    values[`${prefix}.switcher.countryStyle`] =
      active[prefix].switcher.countryStyle;
    values[`${prefix}.switcher.currencyStyle`] =
      active[prefix].switcher.currencyStyle;
    values[`${prefix}.switcher.flagStyle`] = active[prefix].switcher.flagStyle;
    values[`${prefix}.switcher.showFlag`] = active[prefix].switcher.showFlag;

    form.setFieldsValue(values);
  };

  // Get selected design id from the form
  const selected = form.getFieldValue(`${prefix}.switcher.designId`);

  return (
    <Form.Item
      name={`${prefix}.switcher.mappedToDesign`}
      key='form-item'
      normalize={(val) => {
        // If mapped to design is true update the form key's
        if (val) {
          handleDesignSelect(form.getFieldValue(`${prefix}.switcher.designId`));
        }
        return val;
      }}
    >
      <Radio.Group>
        <Space direction='vertical' size={24}>
          <Space direction='vertical'>
            <Radio value>
              <Space direction='vertical' size={0}>
                <Text strong>Recommended designs for your store</Text>
                <Text>Choose a design</Text>
              </Space>
            </Radio>
            {form.getFieldValue(`${prefix}.switcher.mappedToDesign`) && (
              <div className='pl-40'>
                <Form.Item
                  name={`${prefix}.switcher.designId`}
                  rules={[{ required: true }]}
                >
                  <Radio.Group>
                    <Row
                      gutter={[12, 12]}
                      style={{ paddingRight: 10 }}
                      wrap
                      justify='space-between'
                      className='designs'
                    >
                      {[...designs].map((design, index) => (
                        <Col
                          xs={24}
                          sm={12}
                          md={12}
                          lg={12}
                          key={design[prefix]?.designId}
                        >
                          <SwitcherDesign
                            recommended={index === 0}
                            selected={selected === design[prefix].designId}
                            id={design[prefix].designId}
                            value={design[prefix].designId}
                            code={design[prefix]?.switcher?.countryStyle}
                            currencyStyle={
                              design[prefix]?.switcher?.currencyStyle
                            }
                            imageSrc={design[prefix]?.switcher?.designIconUrl}
                            description={design[prefix]?.switcher?.designName}
                            handleClick={handleDesignSelect}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </div>
            )}
          </Space>
          <Space direction='vertical' size={16}>
            <Radio value={false}>
              <Space direction='vertical' size={0}>
                <Text strong>Other</Text>
                <Text>Create your own design</Text>
              </Space>
            </Radio>
            {!form.getFieldValue(`${prefix}.switcher.mappedToDesign`) && (
              <Space
                className='pl-40'
                direction='vertical'
                size={12}
                // className={classes.subSection}
              >
                <Form.Item
                  name={`${prefix}.switcher.flagStyle`}
                  // if all the styles are none then display the error
                  rules={[
                    {
                      message: 'select at least one field.',
                      validator: (rule, value) => {
                        if (
                          value === 'none' &&
                          form.getFieldValue(
                            `${prefix}.switcher.countryStyle`,
                          ) === 'none' &&
                          form.getFieldValue(
                            `${prefix}.switcher.currencyStyle`,
                          ) === 'none'
                        ) {
                          return Promise.reject();
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  label={
                    <FormLabel
                      title='Choose flag style'
                      // subTitle='Choose how you want to display flag image on the switcher.'
                    />
                  }
                  normalize={(val) => {
                    if (val === 'none') {
                      if (prefix === 'desktop') {
                        form.setFieldsValue({
                          'desktop.switcher.showFlag': false,
                        });
                      } else if (prefix === 'mobile') {
                        form.setFieldsValue({
                          'mobile.switcher.showFlag': false,
                        });
                      }
                    } else if (prefix === 'desktop') {
                      form.setFieldsValue({
                        'desktop.switcher.showFlag': true,
                      });
                    } else if (prefix === 'mobile') {
                      form.setFieldsValue({
                        'mobile.switcher.showFlag': true,
                      });
                    }
                    return val;
                  }}
                >
                  <Select>
                    <Option value='circle'>Rounded</Option>
                    <Option value='rectangle'>Rectangular</Option>
                    <Option value='none'>No</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name={`${prefix}.switcher.countryStyle`}
                  // if all the styles are none then display the error
                  rules={[
                    {
                      message: 'select at least one field.',
                      validator: (rule, value) => {
                        if (
                          value === 'none' &&
                          form.getFieldValue(`${prefix}.switcher.flagStyle`) ===
                            'none' &&
                          form.getFieldValue(
                            `${prefix}.switcher.currencyStyle`,
                          ) === 'none'
                        ) {
                          return Promise.reject();
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  label={
                    <FormLabel
                      title='Choose country name style'
                      // subTitle='Choose how you want to display country name on the switcher.'
                    />
                  }
                >
                  <Select>
                    <Option value='name'>Full name (Shopify)</Option>
                    <Option value='iso_code'>2 digit code</Option>
                    <Option value='none'>No</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name={`${prefix}.switcher.currencyStyle`}
                  // if all the styles are none then display the error
                  rules={[
                    {
                      message: 'select at least one field.',
                      validator: (rule, value) => {
                        if (
                          value === 'none' &&
                          form.getFieldValue(
                            `${prefix}.switcher.countryStyle`,
                          ) === 'none' &&
                          form.getFieldValue(`${prefix}.switcher.flagStyle`) ===
                            'none'
                        ) {
                          return Promise.reject();
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  label={
                    <FormLabel
                      title='Choose currency name style'
                      // subTitle='Choose how you want to display currency name on the switcher.'
                    />
                  }
                >
                  <Select>
                    <Option value='iso_code'>3 digit code</Option>
                    <Option value='symbol'>Symbol</Option>
                    <Option value='name'>Full name (Shopify)</Option>
                    <Option value='none'>No</Option>
                  </Select>
                </Form.Item>
              </Space>
            )}
          </Space>
        </Space>
      </Radio.Group>
    </Form.Item>
  );
}

export default DesignSection;
