import { useEffect, useState } from 'react';
import axios from 'axios';
import Space from 'antd/lib/space';
import { Col, Row } from 'antd/lib/grid';
import Form from 'antd/lib/form';
import Spin from 'antd/lib/spin';
import Button from 'antd/lib/button';
import message from 'antd/lib/message';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import { usePrefixContext } from '../../../context/PrefixContext';
import CountrySelector from '../../../components/CountrySelector';
import MarketSelector from '../../../components/MarketSelector';

function Content({ form, nextBtnLoading, backBtnLoading, handleBack }) {
  const prefix = usePrefixContext();
  const [loading, setLoading] = useState(true);
  const [themeInfo, setThemeInfo] = useState(null);

  useEffect(() => {
    // Theme compatible api
    axios
      .get('/theme-design/compatible')
      .then((res) => {
        setThemeInfo(res.data);
        form.validateFields();
      })
      .catch((err) => {
        console.error(err);
        message.error('Something went wrong');
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Spin spinning={loading} indicator={<LoadingOutlined />}>
      <Row className='stepContainer'>
        <Col xs={24} md={16} className='leftSection'>
          <MarketSelector themeInfo={themeInfo} />
        </Col>
        <Col xs={24} md={8} className='rightSection'>
          <Form.Item dependencies={['desktop.position']}>
            {({ getFieldValue }) =>
              getFieldValue('desktop.position') !== 'none' && (
                <Space direction='vertical' style={{ width: '100%' }}>
                  <div className='previewText'>Preview</div>
                  <CountrySelector device={prefix} />
                </Space>
              )
            }
          </Form.Item>
        </Col>
        <Col xs={24} md={16}>
          <div className='save-btn-wrapper'>
            <Space align='center' size={24}>
              <Button
                style={{ width: 85 }}
                loading={backBtnLoading}
                onClick={handleBack}
              >
                Back
              </Button>
              <Button
                type='primary'
                htmlType='submit'
                style={{ width: 170 }}
                loading={nextBtnLoading}
              >
                Next
                <i className='las la-arrow-right' />
              </Button>
            </Space>
          </div>
        </Col>
      </Row>
    </Spin>
  );
}

export default Content;
