import { createContext, useContext } from 'react';

const PrefixContext = createContext('desktop');

export const PrefixContextProvider = PrefixContext.Provider;

export const usePrefixContext = () => {
  const prefix = useContext(PrefixContext);

  if (prefix == null) {
    throw Error(
      'Please wrap this component in PrefixContextProvider and pass non null value',
    );
  }

  return prefix;
};

export default PrefixContext;
