import $ from 'jquery';
import Form from 'antd/lib/form';
import Radio from 'antd/lib/radio';
import Space from 'antd/lib/space';
import Typography from 'antd/lib/typography';
import Checkbox from 'antd/lib/checkbox';
import { Row, Col } from 'antd/lib/grid';
import Select from 'antd/lib/select';
import { useLayoutEffect, useRef } from 'react';
import SwitcherDesign from '../SwitcherDesign';
import FormLabel from '../FormLabel';
import { usePrefixContext } from '../../context/PrefixContext';

const { Text } = Typography;
const { Option } = Select;

function MarketSelectorDropdown({ designs }) {
  const prefix = usePrefixContext();
  const mountedRef = useRef(true);

  // its call just before dom render
  useLayoutEffect(
    // cleanup function
    () => () => {
      mountedRef.current = false;
    },
    [],
  );

  return (
    <Form.Item
      dependencies={[
        `${prefix}.switcherDropDown.mappedToDesign`,
        `${prefix}.switcherDropDown.flagStyle`,
        `${prefix}.switcherDropDown.countryStyle`,
        `${prefix}.switcherDropDown.currencyStyle`,
        `${prefix}.switcherDropDown.showFlag`,
        `${prefix}.countrySearch`,
      ]}
    >
      {(form) => {
        const selected = form.getFieldValue(
          `${prefix}.switcherDropDown.designId`,
        );

        // Open the market selector dropdown on any change
        if ($('#country-selector')?.hasClass('select2-hidden-accessible')) {
          // Select2 has been initialized

          setTimeout(() => {
            if (mountedRef.current === true) {
              $('#country-selector')?.select2('open');
            }
          }, 5);
        }

        const handleDesignSelect = (newValue) => {
          const active = [...designs].find(
            (design) => design[prefix]?.designId === newValue,
          );

          // data to be updated in the form instance
          const values = {};
          values[`${prefix}.switcherDropDown.designId`] =
            active[prefix].designId;
          values[`${prefix}.switcherDropDown.countryStyle`] =
            active[prefix].switcherDropDown.countryStyle;
          values[`${prefix}.switcherDropDown.currencyStyle`] =
            active[prefix].switcherDropDown.currencyStyle;
          values[`${prefix}.switcherDropDown.flagStyle`] =
            active[prefix].switcherDropDown.flagStyle;
          values[`${prefix}.switcherDropDown.showFlag`] =
            active[prefix].switcherDropDown.showFlag;

          form.setFieldsValue(values);
        };
        return (
          <Form.Item
            name={`${prefix}.switcherDropDown.mappedToDesign`}
            normalize={(val) => {
              // if mapped design radio is selected
              // then select the first design for market selector dropdown
              if (val) {
                handleDesignSelect(
                  form.getFieldValue(`${prefix}.switcherDropDown.designId`),
                );
              }
              return val;
            }}
          >
            <Radio.Group>
              <Space direction='vertical' size={24}>
                <Space direction='vertical'>
                  <Radio value>
                    <Space direction='vertical' size={0}>
                      <Text strong>Recommended designs for your store</Text>
                      <Text>Choose a design</Text>
                    </Space>
                  </Radio>
                  {form.getFieldValue(
                    `${prefix}.switcherDropDown.mappedToDesign`,
                  ) && (
                    <div className='pl-40'>
                      <Form.Item name={`${prefix}.switcherDropDown.designId`}>
                        <Radio.Group>
                          <Row
                            gutter={[12, 12]}
                            style={{ paddingRight: 10 }}
                            wrap
                            justify='space-between'
                            className='designs'
                          >
                            {[...designs]
                              .filter(
                                (item) =>
                                  item[prefix]?.switcherType ===
                                  'switcherDropDown',
                              )
                              .map((design, index) => (
                                <Col xs={24} sm={12} md={12} lg={12}>
                                  <SwitcherDesign
                                    key={design[prefix]?.designId}
                                    recommended={index === 0}
                                    selected={
                                      selected === design[prefix].designId
                                    }
                                    id={design[prefix]?.designId}
                                    value={design[prefix]?.designId}
                                    code={
                                      design[prefix]?.switcherDropDown
                                        ?.countryStyle
                                    }
                                    currencyStyle={
                                      design[prefix]?.switcherDropDown
                                        ?.currencyStyle
                                    }
                                    imageSrc={
                                      design[prefix]?.switcherDropDown
                                        ?.designIconUrl
                                    }
                                    description={
                                      design[prefix]?.switcherDropDown
                                        ?.designName
                                    }
                                    handleClick={handleDesignSelect}
                                  />
                                </Col>
                              ))}
                          </Row>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  )}
                </Space>
                <Space direction='vertical' size={16}>
                  <Radio value={false}>
                    <Space direction='vertical' size={0}>
                      <Text strong>Other</Text>
                      <Text>Create your own design</Text>
                    </Space>
                  </Radio>
                  {!form.getFieldValue(
                    `${prefix}.switcherDropDown.mappedToDesign`,
                  ) && (
                    <Space
                      className='pl-40'
                      direction='vertical'
                      size={12}
                      // className={classes.subSection}
                    >
                      <Form.Item
                        name={`${prefix}.switcherDropDown.flagStyle`}
                        // update flagStyle and showFlag keys in form instance
                        normalize={(val) => {
                          if (val === 'none') {
                            if (prefix === 'desktop') {
                              form.setFieldsValue({
                                'desktop.switcherDropDown.showFlag': false,
                              });
                            } else if (prefix === 'mobile') {
                              form.setFieldsValue({
                                'mobile.switcherDropDown.showFlag': false,
                              });
                            }
                          } else if (prefix === 'desktop') {
                            form.setFieldsValue({
                              'desktop.switcherDropDown.showFlag': true,
                            });
                          } else if (prefix === 'mobile') {
                            form.setFieldsValue({
                              'mobile.switcherDropDown.showFlag': true,
                            });
                          }
                          return val;
                        }}
                        rules={[
                          {
                            message: 'select at least one field.',
                            // check for all the select fields are "no" or not
                            validator: (rule, value) => {
                              if (
                                value === 'none' &&
                                form.getFieldValue(
                                  `${prefix}.switcherDropDown.countryStyle`,
                                ) === 'none' &&
                                form.getFieldValue(
                                  `${prefix}.switcherDropDown.currencyStyle`,
                                ) === 'none'
                              ) {
                                return Promise.reject();
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                        label={
                          <FormLabel
                            title='Choose flag style'
                            // subTitle='Choose how you want to display flag image on the switcher dropdown.'
                          />
                        }
                      >
                        <Select>
                          <Option value='circle'>Rounded</Option>
                          <Option value='rectangle'>Rectangular</Option>
                          <Option value='none'>No</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name={`${prefix}.switcherDropDown.countryStyle`}
                        // check for all the select fields are "no" or not
                        rules={[
                          {
                            message: 'select at least one field.',
                            validator: (rule, value) => {
                              if (
                                value === 'none' &&
                                form.getFieldValue(
                                  `${prefix}.switcherDropDown.flagStyle`,
                                ) === 'none' &&
                                form.getFieldValue(
                                  `${prefix}.switcherDropDown.currencyStyle`,
                                ) === 'none'
                              ) {
                                return Promise.reject();
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                        label={
                          <FormLabel
                            title='Choose country name style'
                            // subTitle='Choose how you want to display country name on the switcher dropdown.'
                          />
                        }
                      >
                        <Select>
                          <Option value='name'>Full name (Shopify)</Option>
                          <Option value='iso_code'>2 digit code</Option>
                          <Option value='none'>No</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name={`${prefix}.switcherDropDown.currencyStyle`}
                        // check for all the select fields are "no" or not
                        rules={[
                          {
                            message: 'select at least one field.',
                            validator: (rule, value) => {
                              if (
                                value === 'none' &&
                                form.getFieldValue(
                                  `${prefix}.switcherDropDown.countryStyle`,
                                ) === 'none' &&
                                form.getFieldValue(
                                  `${prefix}.switcherDropDown.flagStyle`,
                                ) === 'none'
                              ) {
                                return Promise.reject();
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                        label={
                          <FormLabel
                            title='Choose currency name style'
                            // subTitle='Choose how you want to display currency name on the switcher dropdown.'
                          />
                        }
                      >
                        <Select>
                          <Option value='iso_code'>3 digit code</Option>
                          <Option value='symbol'>Symbol</Option>
                          <Option value='name'>Full name (Shopify)</Option>
                          <Option value='none'>No</Option>
                        </Select>
                      </Form.Item>
                    </Space>
                  )}
                </Space>
                <Form.Item
                  name={`${prefix}.countrySearch`}
                  valuePropName='checked'
                >
                  <Checkbox>
                    Show search bar in the dropdown (Recommended)
                  </Checkbox>
                </Form.Item>
              </Space>
            </Radio.Group>
          </Form.Item>
        );
      }}
    </Form.Item>
  );
}

export default MarketSelectorDropdown;
