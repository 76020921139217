import { Navigate } from 'react-router-dom';

export default function ProtectedRoute({ settings, children }) {
  if (settings.isOnboardingCompleted) {
    return children;
  }

  return <Navigate to='/' />;
}

export function ProtectedRouteOnBording({ settings, children }) {
  if (!settings.isOnboardingCompleted) {
    return children;
  }

  return <Navigate to='/settings' />;
}
