import ChromePicker from 'react-color/lib/Chrome';
import { toState } from 'react-color/lib/helpers';
import Form from 'antd/lib/form';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

function Ele({ value, onChange, onValueChange }, ref) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onValueChange(value), [value]);

  useImperativeHandle(
    ref,
    () => ({
      onChange,
      value,
    }),
    [onChange, value],
  );

  return null;
}

// eslint-disable-next-line no-func-assign
Ele = forwardRef(Ele);

export default function CustomChromePicker({ name }) {
  const ref = useRef();
  const [val, setVal] = useState('');

  return (
    <>
      <ChromePicker
        color={toState(val)}
        disableAlpha
        onChange={({ hex }) => {
          ref.current?.onChange?.(hex);
        }}
      />
      <Form.Item name={name} noStyle>
        <Ele ref={ref} onValueChange={(newVal) => setVal(newVal)} />
      </Form.Item>
    </>
  );
}
